import React from "react";
// reactstrap components
// import {
//   Button,
//   Card,
//   CardBody,
//   CardFooter,
//   CardTitle,
//   Form,
//   Input,
//   InputGroupAddon,
//   InputGroupText,
//   InputGroup,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import { Stream } from "@cloudflare/stream-react";
import ReactPlayer from 'react-player/youtube';
import Footer from "components/Footers/Footer.js";

// core components

function Player({ videoUrl }) {

    // console.log(video_code, "code");
    // console.log(video_id, "id");

  return (
    <>
        {/* <Stream 
          controls src={"https://www.youtube.com/watch"+videoUrl}
          autoplay="true"
          width= "100%"
          volume="0"
          poster="../../assets/img/lejos-de-casa2.jpg"
        /> */}
        <ReactPlayer
        url={'https://www.youtube.com/embed/' + videoUrl}
        controls={true}
        playing={true}
        volume={1}
        muted={true}
        width="100%"
        height="100%"
        />
      <br/><br/><br/><br/><br/><br/>
      <Footer />
    </>
    
  );
}

export default Player;

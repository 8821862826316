// import React from 'react';
// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';

export const SidebarData = [
  // {
  //   title: 'En cartelera',
  //   path: '/',
  //   // icon: <AiIcons.AiOutlineDesktop style={{color: '#F9C410'}} />,
  //   cName: 'nav-text',
  //   fOnclick: "1"
  // },
  // {
  //   title: 'Próximos estrenos',
  //   path: '/reports',
  //   icon: <IoIcons.FaTheaterMasks  style={{color: '#653288'}} />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Catálago',
  //   path: '/products',
  //   icon: <FaIcons.FaCartPlus  style={{color: '#653288'}} />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Contacto',
    path: '/team',
    //icon: <IoIcons.IoMdPeople  style={{color: '#653288'}} />,
    cName: 'nav-text',
    fOnclick: "2"
  },
  // {
  //   title: 'Messages',
  //   path: '/messages',
  //   icon: <FaIcons.FaEnvelopeOpenText  style={{color: '#653288'}} />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Support',
  //   path: '/support',
  //   icon: <IoIcons.IoMdHelpCircle  style={{color: '#653288'}} />,
  //   cName: 'nav-text'
  // }
];

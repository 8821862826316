import React, { Component } from 'react'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBRow, MDBCol } from 'mdbreact'

export default class modalCorreo extends Component {
  render() {
    return (
      <div>
        <MDBModal className="" isOpen={this.props.modalCorreo}>
          <MDBModalHeader className="colorConviasa white-text" style={{fontWeight: "bold", color:"black", textAlingLast:"center"}}>
            <div style={{fontWeight: "bold", color:"black"}}>Contacto</div>
          </MDBModalHeader>
          <MDBModalBody className="h5" style={{fontWeight: "bold", textAlingLast:"center", color:"black", padding:"10px", margin:"10px", alignSelf: 'center'}}>
            Info@clickaplay.com
          </MDBModalBody>
          <MDBModalBody className="colorConviasa">
            <MDBRow className="modal-error-center">
              {/* <MDBCol xs="12" sm="12" md="12" lg="12" xl="12" className="mt-4 h6" >
                <span style={{color:"black"}}>Cod. error: {this.props.codigoError}</span>
              </MDBCol> */}
              <MDBCol xs="12" sm="12" md="12" lg="12" xl="12">
                <MDBBtn style={{borderColor: "green", radius:"0px", backgroundColor:"green", color:"snow"}} onClick={this.props.toggleModalError} color="green">Aceptar</MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}
import React, { useState, useEffect } from 'react';
//import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, Redirect } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import '../../assets/demo/Navbar.css';
import { IconContext } from 'react-icons';
import { Button } from "reactstrap";
import ModalCorreo from '../../ModalCorreo/ModalCorreo'

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [modalC, setModalC] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const toggleModalCorreo = () => setModalC(!ModalCorreo)

  const executeAction = async (item) => {
    // console.log(item);

    await setRedirect(false)

    switch (item) {
      case "1":
        setRedirect(true)
        break;
      case "2": 
        setModalC(true)
        break;
    
      default:
        console.log("metodo no encontrado");
        break;
    }
  }

  const redirectToIndex = () => {
    if (redirect) {
      return <Redirect to='/i'/>;
    }
  }
  


  useEffect(() => {
    scrollFunction()
  });

  window.onscroll = () => {scrollFunction()};
  const scrollFunction = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementsByClassName("navbar")[0].style.backgroundColor = 'black';
    } else {
      document.getElementsByClassName("navbar")[0].style.backgroundColor = 'transparent';
    }
  }
  
  return (
    <>
    {redirectToIndex()}
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar fixed-top mb-5' style={{zIndex: 50, marginTop: "-10px"}}>
          <Link to='/'>
            <img
                alt="..."
                className="logoresponsive"
                src={require("../../assets/img/clickaplaylogoamarillo.svg")}
            />
          </Link>
          
          {/*<div className='menu-bars'>
              <FaIcons.FaUser className="nav-icons" style={{color: '#653288'}} /> 
              <FaIcons.FaBars className="bar-responsive2" style={{color: '#F9C410'}} onClick={showSidebar} />
            <br/>
            <span className="span-menu" style={{fontWeight:"bold", color: '#F9C410'}} onClick={showSidebar}>Menú</span>
          </div>
          */}
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}} className='bar-responsive'>
                <AiIcons.AiOutlineClose  style={{color:'#F9C410'}}/>
              </Button>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  {/* <Link to={item.path}> */}
                    {item.icon}
                    <span onClick={() => executeAction(item.fOnclick)}>{item.title}</span>
                  {/* </Link> */}
                </li>
              );
            })}
          </ul>
        </nav>
        <ModalCorreo 
            modalCorreo={modalC}
            toggleModalError={toggleModalCorreo}
        />
      </IconContext.Provider>
    </>
  );
}

export default Navbar;

import React from "react";
// import { Container } from "reactstrap";
// reactstrap components
// import {
//   Button,
//   Card,
//   CardBody,
//   CardFooter,
//   CardTitle,
//   Form,
//   Input,
//   InputGroupAddon,
//   InputGroupText,
//   InputGroup,
//   Container,
//   Row,
//   Col,
// } from "reactstrap";
// import { Stream } from "@cloudflare/stream-react";
// import ReactPlayer from 'react-player/youtube';

// core components

function Player(props) {

    // console.log(video_code, "code");
    // console.log(video_id, "id");

    const { 
      code
    } = props.location.state.property_id

  return (
    <>
        {/* {console.log(code)} */}
        {/* <Stream 
          // controls src={"https://watch.cloudflarestream.com/"+idPelicula}
          controls src={"https://iframe.videodelivery.net/eyJhbGciOiJSUzI1NiIsImtpZCI6ImMxZWU3NDg2OTliYzE3MDUxM2U4MTczOTEwMzA5NGQ2IiwidHlwIjoiSldUIn0.eyJleHAiOjE2MDU0OTI1NDcsImtpZCI6ImMxZWU3NDg2OTliYzE3MDUxM2U4MTczOTEwMzA5NGQ2Iiwic3ViIjoiYjAzMzc5YmY1MjhjODQ4NGFlNTVjMDJiMDliYWMxNGUifQ.jXDMETgjljCzh5znxGXUP6sOBNA8jxqix6O8hxazkgIsgKsjRx-v3bESAE8aO3gwLkxqbLUw5XJPd8OegIkGx-Ak8vyvFKqwXeBGJyA7pqNuS_eMXnE25jYhtjXCdiSgaPUBQCF5PYCzUF44hxxwVFCRPnLwBdqX0TqytvwS7G6SO5J1bh2PkmqR7ESqFIeJ4KXLE-4OGtXW3LbcYBbjGI44b9WWhto-GkDO1fheoK7Q0FXPOa1ZZ8XGqodx2qA59LNtHxRgQUBZI90xa4Bz8sZnq3oNNCySfAjpO2Kvri28-bbPdfiQWFNQhesxkpwUa5OXGla-tJnNSHjZM98WXQ"}
          autoplay="true"
          width="100%"
          height="100%"
          volume="1"
        /> */}
        {/* <ReactPlayer
        url={"https://iframe.videodelivery.net/eyJhbGciOiJSUzI1NiIsImtpZCI6ImMxZWU3NDg2OTliYzE3MDUxM2U4MTczOTEwMzA5NGQ2IiwidHlwIjoiSldUIn0.eyJleHAiOjE2MDU0OTI1NDcsImtpZCI6ImMxZWU3NDg2OTliYzE3MDUxM2U4MTczOTEwMzA5NGQ2Iiwic3ViIjoiYjAzMzc5YmY1MjhjODQ4NGFlNTVjMDJiMDliYWMxNGUifQ.jXDMETgjljCzh5znxGXUP6sOBNA8jxqix6O8hxazkgIsgKsjRx-v3bESAE8aO3gwLkxqbLUw5XJPd8OegIkGx-Ak8vyvFKqwXeBGJyA7pqNuS_eMXnE25jYhtjXCdiSgaPUBQCF5PYCzUF44hxxwVFCRPnLwBdqX0TqytvwS7G6SO5J1bh2PkmqR7ESqFIeJ4KXLE-4OGtXW3LbcYBbjGI44b9WWhto-GkDO1fheoK7Q0FXPOa1ZZ8XGqodx2qA59LNtHxRgQUBZI90xa4Bz8sZnq3oNNCySfAjpO2Kvri28-bbPdfiQWFNQhesxkpwUa5OXGla-tJnNSHjZM98WXQ"}
        controls={true}
        playing={true}
        volume={1}
        muted={false}
        width="100%"
        height="100%"
        /> */}
        <div className="marginTop">
          {console.log(code, "codigo pelicula")}
          <iframe
            title="player"
            src={"https://iframe.videodelivery.net/" + code}
            style={{border: 'none', width: '100%', height: '500px'}}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
          ></iframe>
        </div>

    </>
    
  );
}

export default Player;

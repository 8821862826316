import React, { Component } from 'react'
import {MDBRow, MDBIcon} from "mdbreact"
// import "./index.css"

class index extends Component {


  facebookLink = () =>{
    // window.open('https://www.surnet.io/', "_blank")
    window.open('https://www.facebook.com/clickaplay/', "_blank")
  }
  instagramLink = () => {
    // window.open('https://www.instagram.com/surnet_io/', "_blank")
    window.open('https://www.instagram.com/clicka.play/', "_blank")
  }
  twitterLink = () => {
    // window.open('https://twitter.com/Surnet_io', "_blank")
    window.open('https://twitter.com/clickaplay', "_blank")
  }
  youtubeLink = () => {
    // window.open('https://twitter.com/Surnet_io', "_blank")
    window.open('https://www.youtube.com/channel/UCyaeFF0aUC9f-Zsea3J6aeA', "_blank")
  }

  render() {
    return (
      <div className="mt-5 footer-conviasa2 footer">
        <MDBRow  style={{background: "#000000"}} center>
          <nav className="footer-nav" style={{textAlign: 'center'}}>
              <ul style={{fontSize:"25px", width: "100%"}}>
                <li style={{cursor: "pointer"}} onClick={() => this.facebookLink()}>
                  <MDBIcon fab icon="facebook" />
                </li>
                <li style={{cursor: "pointer"}} onClick={() => this.instagramLink()}>
                  <MDBIcon fab icon="instagram" />
                </li>
                <li style={{cursor: "pointer"}} onClick={() => this.twitterLink()}>
                  <MDBIcon fab icon="twitter" />
                </li>
                <li style={{cursor: "pointer"}} onClick={() => this.youtubeLink()}>
                  <MDBIcon fab icon="youtube" />
                </li>
              </ul>
          </nav>
        </MDBRow>
        {/* <MDBRow className="colorConviasaG" center>
          <p className="white-text mt-3">CONVIASA  &copy; {new Date().getFullYear()}</p>
        </MDBRow> */}
      </div>
    )
  }
}

export default index
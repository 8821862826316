import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import './assets/demo/demo.css'
// pages
import LandingPage from "views/examples/LandingPage.js";
import FichaInterna from "views/examples/Fichainterna.js";
import PlayerPelicula from "components/Player/PlayerPelicula"
import Navbar from "components/Navbars/Navbar.js";
// import Footer from "components/Footers/Footer2.js";
// others
import { BreakpointsProvider } from "react-with-breakpoints";
//import Player from "components/Player/Player";


const breakpoints = { small: 744, medium: 1128, large: 1440, xlarge: Infinity }


ReactDOM.render(
  <BrowserRouter basename="">
    <BreakpointsProvider breakpoints={breakpoints}>
      <Navbar />
        <Switch>
          {/* <Route path="/index" render={(props) => <Index {...props} />} /> */}
          {/* <Route
            path="/nucleo-icons"
            render={(props) => <NucleoIcons {...props} />}
          /> */}
          <Route
            path="/i"
            render={(props) => <LandingPage {...props} />}
          />
          <Route
            path="/ficha-interna"
            render={(props) => <FichaInterna {...props} />} 
          />
          <Route
            path="/player"
            render={(props) => <PlayerPelicula {...props} />} 
          />
          <Redirect to="/i" />
        </Switch>
        {/* <Footer /> */}
    </BreakpointsProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

import React, { Component } from 'react'
import { MDBModal, MDBModalHeader, MDBModalBody, MDBBtn, MDBRow, MDBCol } from 'mdbreact'
import { Link } from 'react-router-dom';

const parseNumbers = ( num ) => {
  var totalStr = '';
  var numStr = num + '';
  var parts = numStr.split( '.' );
  var numLen = parts[0].length;

  for ( var i = 0; i < numLen; i++ ) {
      var y = numLen - i;
      if ( i > 0 && y % 3 === 0 ) {
          totalStr += y >= 6 ? '.' : '.';
      }

      totalStr += parts[0].charAt(i);
  }
  return totalStr + ',' + ( parts[1] ? parts[1] : '00');
}

export default class modalErrores extends Component {
  render() {
    return (
      <div>
        <MDBModal className="py-1" isOpen={this.props.modalResumen}>
          <MDBModalHeader className="colorConviasa" style={{color:"black"}}>
            Resumen
          </MDBModalHeader>
          <MDBModalBody className="h5" style={{fontWeight: "bold", alingSelf: "center", textAling: "center", textAlignLast:"center"}}>
            {this.props.messageResumen}
            <MDBRow xs="12" sm="12"  lg="12" md="12" xl="12" style={{color:"black", textAling: "center", textAlignLast:"center"}}>
              <MDBCol style={{textAlign: 'center'}}>
                <span style={{color:"black", textAlignLast:"center"}}>Referencia: {this.props.referenceResumen ? this.props.referenceResumen : this.props.referencePM}</span>
              </MDBCol>
            </MDBRow>
            <MDBRow xs="12" sm="12"  lg="12" md="12" xl="12" style={{color:"black", textAling: "center", textAlignLast:"center"}}>
              <MDBCol style={{textAlign: 'center'}}>
                <span style={{color:"black"}}>Bs {this.props.amountResumen ? parseNumbers(this.props.amountResumen) : parseNumbers(this.props.precio_pelicula_bs)} </span>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow xs="12" sm="12"  lg="12" md="12" xl="12" style={{color:"black", textAling: "center", textAlignLast:"center"}}>
              <MDBCol style={{textAlign: 'center'}}>
                <span style={{color:"black"}}>Estimado usuario, le recordamos que dispone de 24 horas para disfrutar de su película. <br/><br/>

                Podrá acceder nuevamente desde el botón alquilar ahora utilizando el correo electrónico registrado.</span>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalBody className="colorConviasa" >
            <MDBRow xs="12" sm="12"  lg="12" md="12" xl="12" style={{color:"snow", justifyContent:"center"}}>
            <MDBCol>
              <Link to={{
                  pathname: "/index",
                }}
              >
                <MDBBtn color="success" onClick={this.props.ejecutarPelicula} style={{borderColor: "green", radius:"0px", backgroundColor:"green"}}>Ver después</MDBBtn>
              </Link>
            </MDBCol>
            <MDBCol>
              <Link to={{
                  pathname: "/player",
                  state: { 
                    property_id: {
                      code: this.props.ulr_pelicula_token
                    } 
                  }
                }}
              >
                <MDBBtn color="success" onClick={this.props.ejecutarPelicula} style={{borderColor: "green", radius:"0px", backgroundColor:"green"}}>Ver Pelicula</MDBBtn>
              </Link>
            </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}


import React, {useState} from "react";
import { ShowAt } from 'react-with-breakpoints';
import { Redirect } from 'react-router-dom';
// reactstrap components
import {
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  // Label
} from "reactstrap";

import Player from "components/Player/Player";
import Poster from "../../components/Cards/FichaInterna/Poster.js";
import Title from "../../components/Cards/FichaInterna/Title/Title";
import FormaPago from "../../components/FormaPago/index";
import ModalErrores from "../../ModalError/ModalErrores";
import ModalResumen from "../../components/ModalResumen/ModalResumen";
import ModalCuponF from "../../components/ModalCupon/ModalCupon";
import Footer from "components/Footers/Footer.js";
import axios from 'axios';

function FichaInterna(props) {

  const [data, setData] = React.useState({
    isTyping: false,
    input1: '',
    input2: '',
    lastInput: '',

    //state toggle pagoMovil
    bancoPM: "",
    codCedulaPM: "",
    cedulaTitularPM: "",
    diaPM: "",
    mesPM: "",
    añoPM: "",
    nroTelefonoPM: "",
    emailPM: "",
    codReferenciaPM: "",
    montoPM: "",
    vuelo: [],
    codigoPagoMovil: "",
    codAreaTlfPM: "",
    resultadoPM:'',

    //redireccion a resumen
    verResumen: false,
    //errores
    modalErrores: false, 
    codError: '', 
    mensajeError: '',
    invoice: '',
    
    //codigos de resumen
    messageResumen: '',
    referenceResumen: '',
    amountResumen:'',
    modalResumen: false,
    modalCupon: false,
    modalTipoPago: false,
    //ip
    ip: '',
    ipLocal:'',
    locator: '',
    //spinner  
    spinnerContinuar: false,
    spinner_comprar: false,
    //pelicula
    token_pelicula: '',
    tipo_moneda: '',
    monto_bs: '',
    monto_usd: '',
    monto_btc: '',
    monto_bs_view: '',
    monto_usd_view: '',
    referencePM: '',
    token_pelicula_repeat: '',
    go_to_player: false,


    //prueba
    newLocalizador: "",
    newIdExt: "",
    newDataIp: "",
    newEmail: "",
    newIdExt2: "",
    newDataIp2: "",
    newEmail2: "",
    
  })
  
  const [modal2, setModal2] = useState(false);
  const [modalProx, setModalProx] = useState(false);
  const [modalCorreo, setModalCorreo] = useState(false);
  // const [modalCupon, setModalCupon] = useState(false);
  const [urlPlayer, setUrlPlayer] = useState('');

  const { 
    actor1,
    actor2,
    actor3,
    actor4,
    actor5,
    actor6,
    img,
    title,
    videoUrl,
    genero,
    direccion,
    estreno,
    sinopsis,
    precioVideo,
    idExt,
    // monto_bs,
    // monto_usd,
    // monto_btc,
    // monto_bs_view,
    // monto_usd_view,
    // referencePM
  } = props.location.state.property_id;
  const { 
    montoUSD,
    montoVES
  } = props.location.state.precios;

  const setAllData = (e) => {
     console.log(e, "valor en ficha interna");

    setData({
      ...data,
      messageResumen:  e.message,
      referenceResumen:  e.reference,
      amountResumen: e.amount,
    })
  };
  const setAllData1 = (e) => {
    // console.log(e, "valor en ficha interna");

    setData({
      ...data,
      referencePM:  e.ref,
    })
  };
  const setAllDataPeli = (e) => {
    // console.log(e, "valor en ficha interna, peli");

    setData({
      ...data,
      token_pelicula:  e.token,
    })
  };
  const getIPLocal = async () => {
    const url = `https://api.ipify.org?format=json`
    const general_locator = await randomLocator(15)
    await axios({
      method: 'get',
      url: url,
    })
    .then(response => response.data)
    .then((data) => {
      // console.log(data);
      setData({
        ...data,
        ip: data.ip,
        locator: general_locator
      })
    })
    .catch((error) => {
      if (error.response) {
        setData({
          ...data,
          modalErrores: true, 
          codError: error.response.data.codigo,
          mensajeError: error.response.data.mensaje,
          spinner: false, 
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        setData({
          ...data,
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        setData({
          ...data,
          modalErrores: true,
          codError: 'Error',
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      }
    })
  };
  const getIPLocal2 = async () => {
    const url = `https://api.ipify.org?format=json`
    // const general_locator = await randomLocator(15)
    await axios({
      method: 'get',
      url: url,
    })
    .then(response => response.data)
    .then((data) => {
      // console.log(data);
      setData({
        ...data,
        ip: data.ip,
        // locator: general_locator
      })
    })
    .catch((error) => {
      if (error.response) {
        setData({
          ...data,
          modalErrores: true, 
          codError: error.response.data.codigo,
          mensajeError: error.response.data.mensaje,
          spinner: false, 
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        setData({
          ...data,
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        setData({
          ...data,
          modalErrores: true,
          codError: 'Error',
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      }
    })
  };
  // const goToPlayer = (e) => {
  //   console.log(e);
  //   if (data.token_pelicula_repeat !== "") {
  //       return <Redirect to={{
  //         pathname: "/player",
  //         state: { 
  //           property_id: {
  //             code: e
  //           } 
  //         }
  //       }}
  //     />
  //   } else {
  //     return null
  //   }
  // };
  const reiniciarURL = async (e) => {
    e.preventDefault()
    setData({
      ...data,
      spinnerContinuar: true,
    })
    const url = `https://prod.flexve.com/ReinicarUrl`

    let payload = {
      email: data.input1, 
      idMovie: idExt,
    }

    await axios({
      method: 'post',
      url: url,
      data: payload,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((datas) => {
      // console.log("reiniciar", datas);
      let localizador_s = randomLocator(15);
      if (data.ip === undefined) {
        getIPLocal2();
      }
     
      if (datas.code === '00' && datas.status_pay === 'true') {
       setData({
          ...data,
          spinnerContinuar: false,
          token_pelicula_repeat: datas.token,
          go_to_player: true
        })
        setUrlPlayer(datas.token)
      } else if (datas.code === '00' && datas.status_pay === 'false') {
        registroPelicula(data.locator === undefined ? localizador_s : data.locator, idExt, data.ip, data.input1, localizador_s)
        setData({
          ...data,
          newLocalizador: data.locator === undefined ? localizador_s : data.locator,
          newIdExt: idExt,
          newDataIp: data.ip,
          newEmail: data.input1
        })
      }else if (datas.code === '08') {
        registroPelicula(data.locator === undefined ? localizador_s : data.locator, idExt, data.ip, data.input1, localizador_s)
        setData({
          ...data,
          newLocalizador: data.locator === undefined ? localizador_s : data.locator,
          newIdExt: idExt,
          newDataIp: data.ip,
          newEmail: data.input1
        })
      }
      else if (datas.code === '09' && datas.status_pay === 'false') {
        registroPelicula(data.locator === undefined ? localizador_s : data.locator, idExt, data.ip, data.input1, localizador_s)
        setData({
          ...data,
          newLocalizador: data.locator === undefined ? localizador_s : data.locator,
          newIdExt: idExt,
          newDataIp: data.ip,
          newEmail: data.input1
        })
      }else if (datas.code === '09' && datas.status_pay === "true") {
        registroPelicula(data.locator === undefined ? localizador_s : data.locator, idExt, data.ip, data.input1, localizador_s)
        setData({
          ...data,
          newLocalizador: data.locator === undefined ? localizador_s : data.locator,  
          newIdExt: idExt,
          newDataIp: data.ip,
          newEmail: data.input1
        })
      } else {
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else if (error.request) {
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else {
        setData({
          ...data,
          modalErrores: true, 
          codError: '008', 
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
  };
  const takeMail = (e) => {
    setData({
      ...data,
      input1: e.target.value
    });
    // console.log(e.target.value, "mail");
  };
  const openResume = () => {
    setData({
      ...data,
      modalResumen: true
    })
  };
  const toggleModalResumen = () => {
    setData({
      ...data,
      modalResumen: false
    })
  };
  const toggleModalCupon = (e) => {
    setData({
      ...data,
      modalCupon: e
    })
  };
  const toggleModalError =() => {
    setData({
      ...data,
      modalErrores: false
    })
  };
  const cerrarModal2 = () => {
    setModal2(false)
  };
  const parseNumbers1 = ( num ) => {
    var totalStr = '';
    var numStr = num + '';
    var parts = numStr.split( '.' );
    var numLen = parts[0].length;
  
    for ( var i = 0; i < numLen; i++ ) {
        var y = numLen - i;
        if ( i > 0 && y % 3 === 0 ) {
            totalStr += y >= 6 ? '.' : '.';
        }
  
        totalStr += parts[0].charAt(i);
    }
    return totalStr + ',' + ( parts[1] ? parts[1] : '00');
  };
  const parseNumbers = ( num ) => {
    var totalStr = '';
    var numStr = num + '';
    var parts = numStr.split( '.' );
    var numLen = parts[0].length;
  
    for ( var i = 0; i < numLen; i++ ) {
        var y = numLen - i;
        if ( i > 0 && y % 3 === 0 ) {
            totalStr += y >= 6 ? '.' : '.';
        }
  
        totalStr += parts[0].charAt(i);
    }
    return totalStr + ',' + ( parts[1] ? parts[1] : '00');
  };
  const randomLocator = (length) => {
    var result = '';
    var characters = '1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const registroPelicula = async (localizador, idext, ip, email, locatorE) => {
    const url = `https://prod.flexve.com/registroPelicula`
    console.log(locatorE);
    console.log(data.locator);
    
    let datosPagoMovil = {
      locator: data.locator === undefined ? locatorE : data.locator,
      email: data.input1, 
      idmovie: idExt,
      ip: data.ip ? data.ip : data.newDataIp2,
      amount: montoVES,
      coin: "VES",
      demo_code: "n/a"
    }

    axios({
      method: 'post',
      url: url,
      data: datosPagoMovil,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      if (data.code === '01') {
        // console.log(data)
        setData({
          ...data,
          spinnerContinuar: false,
          tipo_moneda: "BS",
          newLocalizador2: localizador === undefined ? locatorE : localizador,
          newIdExt2: idext,
          newDataIp2: ip === undefined ? data.ip : ip,
          newEmail2: email
        })
        setModalCorreo(false)
        setModal2(true)
      } else {
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        setData({
          ...data,
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        setData({
          ...data,
          modalErrores: true, 
          codError: '008', 
          mensajeError: 'Por favor, reintente el proceso, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
  }
  const updatePagoCompletado = () => {
    const url = `https://prod.flexve.com/PagoCompletado`
    
    let datosPeliculas = {
      locator: data.newLocalizador2,
      email: data.newEmail2,
      idmovie: data.newIdExt2,
      ip: data.newDataIp2
    }

    axios({
      method: 'post',
      url: url,
      data: datosPeliculas,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data, "proceso");
      if (data.codre === '00') {
        console.log('aprobado');
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        this.setState({ 
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      }
    })

  }

  React.useEffect(() => {
    getIPLocal()
  }, [])
  
  let spinnerContinuar
  if(data.spinnerContinuar){
      spinnerContinuar = <div>
                            <Button 
                              type="button" 
                              block
                              className="boton-modal-1-aceptar block"
                              style={{borderRadius:"0", color:"snow" , marginTop:"10px"}}
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm white-text" role="status" color="white">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </Button>   
                          </div>
  } else{
      spinnerContinuar = <div>
                            <Button 
                              type="submit" 
                              block 
                              className="boton-modal-1-aceptar block" 
                              style={{borderRadius:"0", color:"snow" , marginTop:"10px"}}
                            >
                              CONTINUAR
                            </Button>   
                          </div>
  };

  return (
    <>
      {data.go_to_player === true && urlPlayer !== ''? 
          <Redirect to={{
            pathname: "/player",
            state: { 
              property_id: {
                code: urlPlayer
              } 
            }
          }}
        />
      : null}
      {/* {console.log("locator1" ,data.newLocalizador)}
      {console.log("pelicode1" ,data.newIdExt)}
      {console.log("ip1" ,data.newDataIp)}
      {console.log("email1" ,data.newEmail)}
      {console.log("locator2" ,data.newLocalizador2)}
      {console.log("pelicode2" ,data.newIdExt2)}
      {console.log("ip2" ,data.newDataIp2)}
      {console.log("email2" ,data.newEmail2)} */}
  
    <Container>
        <Row style={{textAlign: 'center', verticalAlign: 'center'}}>
            <Col xs="12" sm="12" md="12" lg="12">
                <Title title={title} />
            </Col>
            <Col xs="12" sm="12" md="4" lg="4">
                <Poster
                  actor1={actor1}
                  actor2={actor2}
                  actor3={actor3}
                  actor4={actor4}
                  actor5={actor5}
                  actor6={actor6}
                  estreno={estreno}
                  img={img}
                  title={title}
                  videourl={videoUrl}
                />
            </Col>
            <Col xs="12" sm="12" md="8" lg="8" className="mt-3">
            <Player
                videoUrl={videoUrl} 
            />
            </Col>
        </Row>
        <Row >
            <Col lg="4" md="4">
              <Row style={{justifyContent: 'center',textAling:'center'}}>
                  <Row style={{textAlign:"center"}}>
                    <Col xs="6"md="6">
                      <Button
                          style={{height:"30%", width:"100%"}}
                          onClick={() => setModalCorreo(true)}
                          //onClick={proximamente}
                          className="boton-ficha block mb-5 mt-5"
                      > ALQUILAR AHORA
                      </Button>
                    </Col>
                    <Col xs="6" md="6">
                      <Button
                        style={{height:"30%", width:"100%"}}
                        
                        onClick={() => toggleModalCupon(true)}
                        //onClick={proximamente}
                        className="boton-ficha block mb-5 mt-5"
                        > USAR CUPÓN
                      </Button>
                    </Col>
                  </Row>
                    <Col className="preciointerna">
                      {montoVES !== null && montoVES !== "" ? 
                        (
                          <>
                          <span className="h4 precio" style={{width: '100%', backgroundColor:'#0a0a0a'}}>{montoVES ? `Bs. ${parseNumbers(montoVES)}` : 'Cargando...'}</span>
                          </>
                          ) : <span style={{color:"snow"}}>Cargando...</span>  
                        }
                    </Col>
              </Row>
            </Col>
            <Col lg="8" md="8">
              <Row>
                <Col xs="12" sm="12" md="7" lg="7">
                  <Row
                  className="sinopsis"
                  >
                    <h3
                      className="title-sinopsis mb-4"
                    >
                      Sinopsis
                    </h3>
                    {sinopsis}
                  </Row>
                </Col>
                <Col xs="12" sm="12" md="5" lg="5" className="">
                    <Row className="row-inf">
                      <ul>
                        <li>
                          <span className="span.blanco">Título: </span>
                          <span className="li.cont">{title}</span>
                        </li>
                        <li>
                        <span>{`${actor1 ? "Actores: " : ''}`} {`${actor1 ? actor1 + "," : ''}`} {`${actor2 ? actor2 + "," : ''}`} {`${actor3 ? actor3 + "," : ''}`} {`${actor4 ? actor4 + "," : '' }`} {`${actor5 ? actor5 + "," : ''}`} {`${actor6 ? actor6 : ''}`}   <br /></span>
                        </li>
                        <li>
                        <span className="span.blanco">Año de estreno: </span>
                          <span className="li.cont">{estreno} <br /></span>
                        </li>
                        <li>
                          <span className="span.blanco">Género: </span>
                          <span className="li.cont">{genero}</span>
                        </li>
                        <li>
                          <span className="span.blanco">Dirección: </span>
                            <span className="li.cont">{direccion}</span>
                        </li>
                      </ul>
                    </Row>
                </Col>
              </Row>
            </Col>
        </Row>
    </Container>
    <Container>
      <Row style={{justifyContent: "center"}}>
          <Modal isOpen={modalCorreo} className="modal-1">
            <ModalHeader  className="modal-1-title" style={{backgroundColor: '#f9c410'}}>Introduce un correo electrónico para alquilar o seguir viendo la película</ModalHeader>
            <ModalBody className="modal-proximamente-body" style={{padding:0}}>
              <Container className="mt-2 mb-2" style={{placeContent:"center"}}>
                <strong 
                  className="h3" 
                  style={{justifyContent: 'center', fontWeight:"bold"}}
                >
                  Monto a pagar: Bs. {parseNumbers1(montoVES)} 
                </strong>    
              </Container>
              <Form inline onSubmit={reiniciarURL} style={{justifyContent:"center", marginTop:"10px"}}>
                <FormGroup className="mb-3 mt-3">
                  <Row style={{placeContent:"center"}}>
                      <ShowAt breakpoint='xlarge'>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                          <Input
                            autoFocus={true}
                            type="email"
                            className="input-modal-1" 
                            placeholder="Correo electrónico" 
                            bsSize="lg"
                            onChange={takeMail} 
                            required
                          />
                        </Col>
                      </ShowAt>
                      <ShowAt breakpoint='large'>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                          <Input
                            autoFocus={true}
                            type="email"
                            className="input-modal-1" 
                            placeholder="Correo electrónico" 
                            bsSize="lg"
                            onChange={takeMail} 
                            required
                          />
                        </Col>
                      </ShowAt>
                      <ShowAt breakpoint='medium'>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                          <Input
                            autoFocus={true}
                            type="email"
                            className="input-modal-1" 
                            placeholder="Correo electrónico" 
                            bsSize="lg"
                            onChange={takeMail} 
                            required
                          />
                        </Col>
                      </ShowAt>
                      <ShowAt breakpoint='small'>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Input
                              autoFocus={true}
                              type="email"
                              className="input-modal-1" 
                              placeholder="Correo electrónico" 
                              bsSize="sm"
                              onChange={takeMail} 
                              required
                            />
                        </Col>
                      </ShowAt>
                  </Row>
                </FormGroup>
                <Container style={{justifyContent: 'center', backgroundColor: 'yellow', fontWeight:"bold"}}>
                  <strong 
                    className="h6" 
                    style={{justifyContent: 'center', backgroundColor: 'yellow', fontWeight:"bold"}}
                  >
                    Navegadores compatibles: Chrome, Firefox, Safari, Ópera.
                  </strong>    
                </Container>
                <Row style={{width:"100%", textAlign:"center"}} className="modal-1-footer">
                  <Col style={{padding:0, margin:0}} xs="12" sm="12" md="6" lg="6" >
                      <Button 
                        type="button" 
                        block 
                        className="boton-modal-1-cancelar block"
                        style={{borderColor:"red", borderRadius:"0",  color:"snow" , marginTop:"10px"}}
                        onClick={() => setModalCorreo(false)}
                      >Cerrar</Button>
                  </Col>
                  <Col style={{padding:0, margin:0}} xs="12" sm="12" md="6" lg="6">
                    {spinnerContinuar}                
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
      </Row>
    </Container>  
    <Modal isOpen={modal2} toggle={() => setModal2(false)} className="modal-2" >
      <FormaPago 
        precioVid={precioVideo}
        demoCode={videoUrl}
        cedulaVideo={data.input1}
        closeModal2={cerrarModal2}
        openResumen={openResume}
        allData={setAllData}
        allData1={setAllData1}
        allDatapeli={setAllDataPeli}
        email_global={data.input1}
        ip_global={data.newDataIp2}
        url_pelicula={idExt}
        precio_pelicula_bs={montoVES}
        precio_pelicula_usd={montoUSD}
        precio_pelicula_btc={props.monto_btc}
        coin_metodo_pago={data.tipo_moneda === undefined ? "BS" : data.tipo_moneda }
        monto_usd_view={montoUSD}
        monto_bs_view={montoVES}
        locator_global={data.newLocalizador2}
        pCompletado={updatePagoCompletado}

      />
    </Modal>
    <Modal size="100%" style={{width: '100%'}} isOpen={modalProx} toggle={() => setModalProx(false)}>
      <h3 style={{alignSelf: 'center', margin: 0}}>PROXIMAMENTE</h3>
    </Modal>
    <div>
      <ModalErrores 
        toggleModalError={toggleModalError}
        modalErrores={data.modalErrores} 
        mensajeError={data.mensajeError} 
        codigoError={data.codError}
      />
    </div>
    <div>
      <ModalResumen 
        toggleModalResumen={toggleModalResumen}
        modalResumen={data.modalResumen}
        // modalResumen={true}
        referenceResumen={data.referenceResumen} 
        messageResumen={data.messageResumen} 
        amountResumen={data.amountResumen} 
        ulr_pelicula_token={data.token_pelicula}
        referencePM={data.referencePM}
        precio_pelicula_bs={data.monto_bs}
      />
    </div>
    <div>
      <ModalCuponF
        toggleModalCupon={toggleModalCupon}
        modalCupon={data.modalCupon}
        idMovie={idExt}
        ip={data.ip}
      >
      </ModalCuponF>
    </div>
    <br/><br/><br/><br/><br/><br/>
    <Footer />
    </>
  );
}

export default FichaInterna;

import React, { Component } from 'react'
import { MDBModal, MDBModalHeader, MDBModalBody,MDBInput, MDBIcon} from 'mdbreact'
// import { Link } from 'react-router-dom';
import {
    // Form,
    // Input,
    // Container,
    Row,
    Col,
    Button,
    Input,
    // Modal,
    // ModalHeader,
    // ModalBody,
    // FormGroup,
    // Label
    Form
  } from "reactstrap";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import ModalErrores from "../../ModalError/ModalErrores";
// const parseNumbers = ( num ) => {
//   var totalStr = '';
//   var numStr = num + '';
//   var parts = numStr.split( '.' );
//   var numLen = parts[0].length;

//   for ( var i = 0; i < numLen; i++ ) {
//       var y = numLen - i;
//       if ( i > 0 && y % 3 === 0 ) {
//           totalStr += y >= 6 ? '.' : '.';
//       }

//       totalStr += parts[0].charAt(i);
//   }
//   return totalStr + ',' + ( parts[1] ? parts[1] : '00');
// }

export default class ModalCupon extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      spinnerContinuar: false,
      inputCupon: "",
      go_to_player: false,
      modalErrores: false,
      locator: '',
      codError: '',
      mensajeError: '',
      tokenMovie: ''
    })
  }
  handdleCupon = (e) => {
    this.setState({
      inputCupon: e.target.value
    });
     console.log(e.target.value, "cupon");
  };

  randomLocator = (length) => {
    var result = '';
    var characters = '1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  toggleModalError =() => {
    this.setState({
      modalErrores: false
    })
  };
  usarCupon = (e) => {
  e.preventDefault()
  this.setState({
    spinnerContinuar: true,
  })
  const url = `https://prod.flexve.com/promo`
  
  let datosPeliculas = {
    Apitoken: "9719B1EADD70D14917D146BDF8E58F6F3345B6A0C567D402C07A4D043A36F70C",
    Locator: this.randomLocator(15),
    Code: this.state.inputCupon,
    Id_Movie: this.props.idMovie,
    IP: this.props.ip
  }

  axios({
    method: 'post',
    url: url,
    data: datosPeliculas,
    headers: {'Content-Type':'application/json'},
  })
  .then(response => response.data)
  .then((data) => { 
    console.log(data, "proceso");
    if (data.code === '00') {
      this.props.toggleModalCupon(false)
      console.log("aprobado")
      this.setState({
        tokenMovie: data.token,
        go_to_player: true
      })

    } else {
      this.setState({
        modalErrores: true, 
        codError: data.code, 
        mensajeError: data.message,
        spinnerContinuar: false
      })
    }
  })
  .catch((error) => {
    console.log(error.response);
    if (error.response) {
      this.setState({ 
        modalErrores: true, 
        codError: error.response.data.code,
        mensajeError: error.response.data.message,
        spinnerContinuar: !this.state.spinnerContinuar,
        spinner: !this.state.spinner
      })
    } else if (error.request) {
      console.log(error.request);
      this.setState({
        modalErrores: true, 
        codError: error.response.data.code,
        mensajeError: error.response.data.message,
        spinnerContinuar: !this.state.spinnerContinuar,
        spinner: !this.state.spinner
      })
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log('Error', error.message);
      this.setState({
        modalErrores: true, 
        codError: error.response.data.code,
        mensajeError: error.response.data.message,
        spinnerContinuar: !this.state.spinnerContinuar,
        spinner: !this.state.spinner
      })
    }
  })

}

  render() {
    let spinnerContinuar
  if(this.state.spinnerContinuar){
      spinnerContinuar = <div>
                            <Button 
                              type="button" 
                              block
                              className="boton-modal-1-aceptar block"
                              style={{borderRadius:"0", color:"snow" , marginTop:"10px"}}
                              disabled
                            >
                              <div className="spinner-border spinner-border-sm white-text" role="status" color="white">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </Button>   
                          </div>
  } else{
      spinnerContinuar = <div>
                            <Button 
                              type="submit" 
                              block 
                              className="boton-modal-1-aceptar block" 
                              style={{borderRadius:"0", color:"snow" , marginTop:"10px"}}
                            >
                              CONTINUAR
                            </Button>   
                          </div>
  };

    return (
      <div>
        {this.state.go_to_player === true && this.state.tokenMovie !== ''? 
          <Redirect to={{
            pathname: "/player",
            state: { 
              property_id: {
                code: this.state.tokenMovie
              } 
            }
          }}
        />
      : null}
        <MDBModal className="py-1" isOpen={this.props.modalCupon}>
          <MDBModalHeader className="colorConviasa" style={{color:"black"}}>
            Introduce el cupón para ver la película
          </MDBModalHeader>
          <MDBModalBody className="h5" style={{padding:0, margin:0,fontWeight: "bold", alingSelf: "center", textAling: "center", textAlignLast:"center"}}>
              <Form inline onSubmit={(e) => this.usarCupon(e)} >
                <Row style={{padding:0, margin:0,width:"100%", textAlign:"center"}}>
                    <Col xs="1" sm="1" md="3" lg="3" xl="3" className="icon-cupon mt-3 mb-3">
                      <MDBIcon icon="gift" style={{marginRight:"5px"}}/>
                    </Col>
                    <Col xs="10" sm="11" md="3" lg="3" xl="3" style={{textAlignLast:"center", padding:0, margin:0}} className="mt-3 mb-3">
                        <Input
                          style={{marginRight:"5px"}}
                          autoFocus={true}
                          type="text"
                          className="input-modal-1" 
                          placeholder="0000-0000" 
                          onChange={this.handdleCupon}
                          // pattern="[0-9]+"
                          required
                          bsSize="lg"
                          maxlength="9"
                          />
                    </Col>
                </Row>
                <Row style={{padding:0, margin:0,width:"100%", textAlign:"center"}} className="modal-1-footer">
                    <Col style={{padding:0, margin:0}} xs="6" sm="6" md="6" lg="6" >
                        <Button 
                          type="button" 
                          block 
                          className="boton-modal-1-cancelar block"
                          style={{borderColor:"red", borderRadius:"0",  color:"snow" , marginTop:"10px"}}
                          onClick={() => this.props.toggleModalCupon(false)}
                          >Cerrar</Button>
                    </Col>
                    <Col style={{padding:0, margin:0}} xs="6" sm="6" md="6" lg="6">
                      {spinnerContinuar}                
                    </Col>
                </Row>
              </Form>
          </MDBModalBody>
        </MDBModal>
        <div>
            <ModalErrores 
              toggleModalError={this.toggleModalError}
              modalErrores={this.state.modalErrores} 
              mensajeError={this.state.mensajeError} 
              codigoError={this.state.codError}
            />
        </div>
      </div>
      
    )
  }
}

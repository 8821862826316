import React, { Suspense } from 'react';
// reactstrap components
import {
  // Button,
  // Card,
  // CardBody,
  // CardFooter,
  // CardTitle,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  // Container,
  // Row,
  // Col,
} from "reactstrap";

// core components
// import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
// import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footers/Footer2.js";
// import Player from "components/Player/Player";
// import Carousel from "components/Carousel/Carousel"
// import Carameleria from "components/Footers/Carameleria"
// import Marquee from 'components/Marquee/Marquee'
// import Navbar from "components/Navbars/Navbar"
const Cards = React.lazy(() => import('components/Cards/Cards'));
// const Estrenos = React.lazy(() => import('components/Cards/Estrenos/Estrenos'));
const Collage = React.lazy(() => import('components/Collage/Collage'));
// import Cards from "components/Cards/Cards"
// import Estrenos from "components/Cards/Estrenos/Estrenos"
// import Collage from 'components/Collage/Collage'

function LandingPage(props) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="mb-0 mt-0 pt-1">
          <Collage />
        </div>
        <div className="mb-0">
          {/* <Marquee /> */}
        </div>
          {/* <Carousel /> */}
        {/* <Player 
            videoUrl="38123dac4a4b2c0d18a4c91767d8a24b" 
        /> */}
        <div className="mt-0">
          <Cards/>
        </div>
        {/* <div className="mb-5">
          <Estrenos /> 
        </div> */}
        {/* <div>
          <Carameleria />
        </div> */}
        <Footer />
      </Suspense>
    </>
  );
}

export default LandingPage;

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';


const Cards = (props) => {
  return (
    <Container style={{width: '100%', marginTop: '50px', backgroundColor:'#0a0a0a'}}>
        <Row className="mb-0" style={{width: '100%', backgroundColor:'#0a0a0a'}}>
            <Col xs= "12" sm="12" md="12" lg="12" className="mb-0">
                <h2 className="cine-estrenos mb-1" style={{width: '100%', backgroundColor:'#0a0a0a'}}> {props.title} </h2>
            </Col>
        </Row>

    </Container>
  );
};

export default Cards;
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// const infeccion = require ('assets/img/infeccion.jpg');
// const lejosDeCasa = require('assets/img/lejos-de-casa2.jpg');


const Estrenos = (props) => {
  const cards = [
      {
          title: props.title,
          img: props.img,
          videoUrl: props.videourl,
          actor1: props.actor1,
          actor2: props.actor2,
          actor3: props.actor3,
          actor4: props.actor4,
          actor5: props.actor5,
          actor6: props.actor6,
          estreno: props.estreno
      },
  
  ]

  return (
    <Container style={{width: '100%', paddingLeft:0}}>
        <Row>
            {cards.map((e, i) => {
            return      <Col key={i}>
                                <img className="img-poster" style={{borderRadius: 0}} width="100%" src={`${e.img}`} alt="..." />
                                    {/* <Button
                                    style={{backgroundColor: "#F9C410", borderColor: "#000000", color: "black", itemAlign:"center"}}
                                    className="card-btn mb-5"
                                    >Comprar</Button> */}
                                {/* <CardFooter style={{backgroundColor: "#F9C410"}}></CardFooter> */}
                        </Col>
            })}
        </Row>
    </Container>
  );
};

export default Estrenos;
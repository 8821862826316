import React, { Component } from 'react'
import { 
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBInputGroup,
  MDBBtn, 
  MDBModal, 
  MDBModalBody, 
  MDBModalHeader,
  MDBContainer
} from "mdbreact"
import { HideAt, ShowAt } from 'react-with-breakpoints';
// import tokenKiu from '../../helpers/tokens'

import "../../assets/css/index.css"
// import pftdd from '../../assets/img/pftdd.png'
//import pftrf from '../../assets/img/pftrf.png'
// import metroBank from '../../assets/img/metroBank.png'
// import credito  from '../../assets/img/credito.png'
// import petro from '../../assets/img/petro.png'
// import pfpm from '../../assets/img/pfpm.png'
//import zelle_logo from '../../assets/img/zelle.svg'
// import activo from '../../assets/img/activo.png'
//import bod from '../../assets/img/bod.png'
//import tesoro from '../../assets/img/tesoro.png'
// import reserva from '../../assets/img/reserva.png'
import isologoActivo from '../../assets/img/IsologoActivo.png'

//axios
import axios from 'axios'

//dias feriados
// import diasFeriados from '../../helpers/diasFeriados'

//modal errores 
import ModalErrores from '../../ModalError/ModalErrores'

// import ruta from '../../helpers/direccionKiu'

import 'moment/locale/es-us';
const moment = require('moment');
moment.locale('es-us')

export class SelectorPagos extends Component {
  constructor(props) {
    super(props);
    // this.total = this.props.airPrice.TotalFare.TotalInfo.Amount
    this.pagoMovil = this.props.enviarPagoMovil
    this.spinner = this.props.spinner
    this.state = {
      TipoPago: '',
      modalAvisoPM: false,
      radioAvisoPM: true,
      modal: false,
      datosCredito: [],
      botonCredito: true,

      //datos de paises para select
      datosPaises: [],

      //modal petro
      modalPetro: false,

      //correo petro
      correoPetro: '',
      correoPetroC: '',

      //limite insert pago movil
      limitePM: 0,
      
      //variables contador
      modalAvisoSesion: false,
      hour: '',

      //errores
      modalErrores: false, 
      codError: '', 
      mensajeError: '',
      // invoice: '',

      //disabled
      disableTesoro: false,

      //spinner para ventana de petro
      spinnerPetro: false,

      //codigos de condicionales
      codPetro: '',
      codInsert: '',

      //fix
      metodosPago: 'N',

      //proximamente
      modal_proximamente: false
    }

    //modal errores
    this.toggleModalError = this.toggleModalError.bind(this)
  }

  // async componentDidMount() {
  //   await axios.get(`https://api.surnet.io/SendCountries`)
  //   .then(res => {
  //     const datosPaises = res.data;
  //     this.setState({ datosPaises });
  //   })
  //   console.log(this.state.datosPaises[0].time)

  //   let [hora] = this.state.datosPaises[0].time.split(':')
  //   console.log(hora)
  //   let horaFloat = parseFloat(hora)
  //   if (horaFloat >= 20 || horaFloat <= 4 ) {
  //     this.setState({
  //       disableTesoro: true
  //     })
  //   } else {
  //     this.setState({
  //       disableTesoro: false
  //     })
  //   }
    
  //   let invoice
  //   let i
  //   for(i=0; i<15; i++) {
  //     let caracteres = "1234567890";
  //     invoice = `${this.props.airBook.BookingReference.Id}-`;
  //     let index
  //     for (index=0; index<15; index++) invoice +=caracteres.charAt(Math.floor(Math.random()*caracteres.length)); 
  //   }

  //   this.setState({invoice})
  // }

  preregistro() {
    const url = `https://test.surnet.io/Test-PagoMovil-SelectPay`

    let datosPagoMovil = {
      FLOCATOR: this.props.localizador,
      FIDEMPRESA: "CCSCON01", 
      TELECONTRAPARTE: "00584265118982",
      AIRLINE: "V0",
      FMONTO: parseFloat(this.props.total),
      EMAIL: this.props.correoPrincipal
    }

    axios({
      method: 'post',
      url: url,
      data: datosPagoMovil,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      if (data.code === '000') {
        this.setState({
          limitePM: 1,
          modalAvisoPM: true,
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso de pago, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        this.setState({
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso de pago, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: '008',
          mensajeError: 'Por favor, reintente el proceso de pago, pulse el boton nuevamente, disculpe las molestias.'
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: '008', 
          mensajeError: 'Por favor, reintente el proceso de pago, pulse el boton nuevamente, disculpe las molestias.'
        })
      }
    })
  }


  
  toggleModalError() {
    this.setState({
      modalErrores: false
    })
  }

  handdleCorreoPetro = e => {
    this.setState({
      correoPetro: e.target.value
    })
    // console.log(e.target.value);
  }

  handdleCorreoPetroC = e => {
    this.setState({
      correoPetroC: e.target.value
    })
    // console.log(e.target.value);
  }

  togglePetro = () => {
    this.setState({
      modalPetro: !this.state.modalPetro
    })
  }

  cambiarMetodo = (e) => {
    let metodo_pago = e.target.value
    this.setState({
      TipoPago: metodo_pago
    });
    this.props.metodoPago(metodo_pago)
  }
  proximamente = () => {
    this.setState({
      modal_proximamente: true
    });
  }

  toggleProximamente = () => {
    this.setState({
      modal_proximamente: false
    });
  }

  toggle = changeEvent => {
    this.setState({
      modal: !this.state.modal,
      radioAvisoPM: !this.state.radioAvisoPM,
    });
    this.props.metodoPago(changeEvent.target.value)
  }
  toggleAvisoPM = (changeEvent) =>{
    // console.log(this.state.modalAvisoPM)
    if (this.state.limitePM < 1) {
      this.preregistro()
      this.setState({
        TipoPago: changeEvent.target.value
      })
    } else {
      this.setState({
        modalAvisoPM: true,
        TipoPago: changeEvent.target.value
      })
    }
    this.props.metodoPago(changeEvent.target.value)
  }
  handleAvisoPago = () => {
    this.setState({
      modal: true,
      modalAvisoPM: false
    })
  }

  toggleCredito = changeEvent => {
    this.setState({
      modalCredito: !this.state.modalCredito,
      TipoPago: changeEvent.target.value,
      botonCredito: true
    })
    this.props.metodoPago(changeEvent.target.value)
  }

  activarBotonCredito = () => {
    this.setState({
      botonCredito: false
    })
  }
  activarRadioAvisoPM = () => {
    this.setState({
      radioAvisoPM: !this.state.radioAvisoPM
    })
  }

  activarCredito = changeEvent => {
    this.primerPasoCredito(changeEvent.target.value)
  }
  
  primerPasoCredito(value) {
    // console.log(typeof this.props.airPrice.TotalFare.TotalInfo.Amount.toString())
    // console.log(typeof this.props.airBook.BookingReference.Id);
    // console.log(typeof this.props.airBook.TravelInfos[0].Email);
    
    window.open('', 'TheWindow', "width=1600,height=auto")
    document.getElementById('TheForm').submit()
    window.location.href = "http://www.conviasa.aero/"
  }

  activarPetro = async () => {
    if (this.state.correoPetro === this.state.correoPetroC) {
      this.setState({
        spinnerPetro: true
      })
      await this.datosPetro()
      // console.log(this.state.codInsert)
      if (this.state.codInsert === '000') {
        await this.pagoPetro()
      }
    } else {
      alert('Ambos correos deben coincidir')
    }
  }

  // async ticketLimitChange() {
  //   const url = `${ruta.urlKiu}:8093/AirBookModify-6`
  //   let token

  //   let datosCambio = {
  //     locator: this.props.localizador
  //   }

  //   if (this.props.scope === 'I') {
  //     if (this.props.currency === 'USD') {
  //       token = tokenKiu.tokenI
  //     } else {
  //       token = tokenKiu.tokenN
  //     }
  //     this.setState({isI11l: true})
  //   } else {
  //     token = tokenKiu.tokenN
  //     this.setState({isI11l: false})
  //   }

  //   await axios({
  //     method: 'post',
  //     url: url,
  //     data: datosCambio,
  //     headers: {
  //       'Content-Type':'application/json',
  //       'Authorization': token
  //     },
  //   })
  //   .then(response => response.data)
  //   .then((data) => { 
  //     this.setState({
  //       codPetro: data.code
  //     })
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       console.log(error.response.data.ErrorMessage);
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: error.response.data.ErrorCode, 
  //         mensajeError: error.response.data.ErrorMessage,
  //         spinnerPetro: false
  //       })
  //     } else if (error.request) {
  //       /*
  //         * The request was made but no response was received, `error.request`
  //         * is an instance of XMLHttpRequest in the browser and an instance
  //         * of http.ClientRequest in Node.js
  //         */
  //       console.log(error.request);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinnerPetro: false
  //       })
  //     } else {
  //       // Something happened in setting up the request and triggered an Error
  //       console.log('Error', error.message);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinnerPetro: false
  //       })
  //     }
  //   })
  // }

  async pagoPetro() {

    var openpgp = require('openpgp'); // use as CommonJS, AMD, ES6 module or via window.openpgp
    await openpgp.initWorker({ path:'openpgp.worker.js' }) // set the relative web worker path

    let data = {
      client: '5dcae1fe4eca411f035986aa',
      // invoice: this.state.invoice,
      coin:"ptr",
      amount: this.props.precioPetro,
      description: 'pago_conviasa',
    }
      //llave publica
      let dataToSend = JSON.stringify(data);

      openpgp.initWorker({ path:'../../../node_modules/openpgp/src/worker/worker.js' })
        .then((val) => {
          console.log(val);
          var pubkey = `-----BEGIN PGP PUBLIC KEY BLOCK-----
            Version: OpenPGP.js v3.1.3
            Comment: https://openpgpjs.org

            xsBNBFyUAcUBB/9vGswL314ZWKNls1zY92HtVkV/H28m2FKohUN98tDjPN/U
            NxUVZ/sjHT6p4ngtdGgWWM6acmX1132hKSEFiKXUO7CtrIv9e6mEK8I2gefU
            J/FYRDZwQYAZLcs7EfmH/k/4dA+ZRbyv1pyylU43cHj3Ut7cFSVUHErGlqat
            9hA1J+x5q3DtBMoKC7VVBVsJji2f5n+QlErbEtjEHdYLQjaVE44wmzftv1tF
            S/aDhZdixn3gRERiDpqW5vEUt2qwTUg+mZUALwKff7nTfekdHfxsp30FErBM
            mTLAhpX/pNPrG8z+/RbNq3E7j8orndsVRVx9BNy317ZFkNZ06zKNgMw9ABEB
            AAHNIkVjby1DcnlwdG8gPGVjb2NyeXB0b0BwYnNjb2luLmNvbT7CwHUEEAEI
            ACkFAlyUAcUGCwkHCAMCCRAv9NYUTEDvggQVCAoCAxYCAQIZAQIbAwIeAQAA
            q8QH/R8hOwRNreUK9RX1/lj2eWbSOwblcEdQHSOPcoH5FFRHsotGmAUZ+EMW
            74K8U4UbEcBAzpcvfasnDETJg4UFjtZCcxuwA+Gpr0T0Fd5YfiE78DqHISXr
            3+c/BBi0jg1ywK4Zd95HjdYpFi2TqXyXGBUd0qDF1yWdNyEloksLkgqDDHNz
            KvTDUtZjFhSFMLGBhF1PVYLS4y9d5qS1gEY9P3/8DvXs6pf/m2lwH6MmB1ql
            BWJvWbMIvVdn6l9zvKuTjRDERCCFa/aAOOTQT38TMcg7hZjvR2I+Fw15aTmQ
            RBJYqU3kJ4SaDSa94GQY5XdKjlJwW4k6myann9WFah/O5S/OwE0EXJQBxQEH
            /38z2P7kM24QKm4a9nK3KtoaeFfXIzibx03ukRWVpaMFaQqHcIXdLyu+Xzj1
            MKd63X3EBKQ7pU960F1+izua05HpWsSW/V2fAAJ7ltSALOgP3XruCxGNyzT+
            SeuxjFW20jaOeZP1Sw8iF2PMdOJUIY7UYjfuutMXRetU1XAqOg0jg3U7dG+r
            0AfRSqL97THaQTUXsuj+PWNUbotXqaVU/HYVSTuSUU+Buc04+ePNfUT7NTlM
            yvmrWE7/u8OG9hXk3LTyFGoX8HSSe50/fzu/FrFEdXadurkIkOqfZf/CtEt6
            GjVQ3SBpA/jrTAa7ew6485OBkVdXlD0DxjJYF2zP+NEAEQEAAcLAXwQYAQgA
            EwUCXJQBxQkQL/TWFExA74ICGwwAADjfB/9tla7/3csC2S2XLAyCzBZYSKMe
            SAsJKpB+iyRxO3tHESK0bxxdOsgvi0FT4gAYoBK/q+IfczeBV0tfEtQOcQtA
            ryAMwpyj88lxHyrIIKoxx5Qgo0WCKP8q5RaGYUJ6g8XdMlmwIuUYXB9yTeAF
            mAbe2luQ+qf2fU4Mld9B8PIGjSqi9UXLqvFxHY4TEUaPPKlhV2wJM+1OV/vB
            iFqY6huclcfKNm4eY4K2GzunsIYrEfotGdU0+PUzSu743/NKK3wgjU893P/E
            OPq0D0LnEI+shhJOO+TBFOq1PIBeElRY+dp5KUJ8M+dvp7Din/CCsxJMYeO9
            Mppqq/qU662Sfo4G
            =+wQC
            -----END PGP PUBLIC KEY BLOCK-----`

      openpgp.key.readArmored(pubkey)
        .then((key) => {
          console.log(key)
        var options = {
          message: openpgp.message.fromText(dataToSend),       // input as Message object
          publicKeys: key.keys, // for encryption
        }

        openpgp.encrypt(options)
          .then((encrypted) => {
            const base64Data = btoa(encrypted.data)

            const petro = window.open("https://petropay-qa.petro.gob.ve/signin/?q=" + base64Data, "Petro", "toolbar=no,scrollbars=yes,status=no,width=1600,height=auto")
            petro.focus()
            window.location.href = "http://www.conviasa.aero"
          })
      })
    })
  }

  parseNumbers1 = ( num ) => {
    var totalStr = '';
    var numStr = num + '';
    var parts = numStr.split( '.' );
    var numLen = parts[0].length;
  
    for ( var i = 0; i < numLen; i++ ) {
        var y = numLen - i;
        if ( i > 0 && y % 3 === 0 ) {
            totalStr += y >= 6 ? '.' : '.';
        }
  
        totalStr += parts[0].charAt(i);
    }
    return totalStr + ',' + ( parts[1] ? parts[1] : '00');
  }
  
  // async datosPetro() {
  //   let url='https://api.surnet.io/PetroInsert'
  //   let datosPetro= {
  //     mail: this.state.correoPetro,
  //     // locator: this.props.airBook.BookingReference.Id,
  //     // invoice: this.state.invoice,
  //     currency: this.props.currency,
  //     airline_code: 'V0',
  //     amount: this.props.precioPetro
  //   }
  //   await axios({
  //     method: 'post',
  //     url: url,
  //     data: datosPetro,
  //     headers: {'Content-Type':'application/json'},
  //   })
  //   .then(response => response.data)
  //   .then((data) => { 
  //     this.setState({
  //       codInsert: data.code
  //     })
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       console.log(error.response.data.ErrorMessage);
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: error.response.data.ErrorCode, 
  //         mensajeError: error.response.data.ErrorMessage,
  //         spinnerPetro: false
  //       })
  //     } else if (error.request) {
  //       /*
  //         * The request was made but no response was received, `error.request`
  //         * is an instance of XMLHttpRequest in the browser and an instance
  //         * of http.ClientRequest in Node.js
  //         */
  //       console.log(error.request);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinnerPetro: false
  //       })
  //     } else {
  //       // Something happened in setting up the request and triggered an Error
  //       console.log('Error', error.message);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinnerPetro: false
  //       })
  //     }
  //   })
  // }

  // canTrf() {
  //   const MAX_HOUR = 20;
  //   const MIN_HOUR = 5;

  //   const today = new Date();
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() + 1);

  //   const day = tomorrow.getDate()
  //   const hour = today.getHours();
  //   const month = tomorrow.getMonth() + 1;
  //   const dateString = `${day}/${month}`;

  //   if ((MIN_HOUR <= hour && hour <= MAX_HOUR) && (day !== 5 && day !== 6)) {
  //     if (diasFeriados.includes(dateString)) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   } else {
  //     return false
  //   }
  // }

  render(){
    //Spinner boton pago movil
    let spinner
    if(this.props.spinner){
      spinner = <div className="spinner-border spinner-border-sm white-text" role="status" color="white">
                  <span className="sr-only">Loading...</span>
                </div>
    } else {
      spinner = <div>Continuar</div>
    }

    let spinnerPetro
    //Spinner boton Petro
    if(this.state.spinnerPetro){
      spinnerPetro =  <div className="spinner-border spinner-border-sm white-text" role="status" color="white">
                        <span className="sr-only">Loading...</span>
                      </div>
    } else {
      spinnerPetro = <div>Continuar</div>
    }
    
    let bb = ""

    //formularios de pago condicionales
    let metodosdePago = <div></div>
    if(this.state.TipoPago === 'tdd') {
      let selectCedula
      if (this.props.bancoDebitoValue === 'bod') {
        selectCedula = <MDBCol md="2" lg="2">
        <MDBInputGroup
          containerClassName="mt-4"
            inputs={
              <select  required className="browser-default custom-select" onChange={this.props.tipoPasajero}>
                <option value="">-</option>
                <option value="V">V</option>
                <option value="E">E</option>
              </select>
            }
          />
      </MDBCol>
      } else {
        selectCedula = <MDBCol md="2" lg="2">
          <MDBInputGroup
            containerClassName="mt-4"
              inputs={
                <select  required className="browser-default custom-select" onChange={this.props.tipoPasajero}>
                  <option value="">-</option>
                  <option value="V">V</option>
                  <option value="E">E</option>
                  <option value="J">J</option>
                  <option value="G">G</option>
                  <option value="C">C</option>
                </select>
              }
            />
        </MDBCol>
      }
        metodosdePago = <div className="formulario">
                          <MDBRow className="colorConviasa caja py-2">
                            <MDBCol>
                              <MDBRow>
                                <strong className="h5 white-text ml-3">Seleccione su banco</strong>    
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="colorAccent caja radio radioMetodoPago">
                            <input 
                              onChange={this.props.bancoDebito} 
                              value="tesoro" 
                              name="banco" 
                              type="radio" 
                              id="bancoTesoro"
                              disabled={this.state.disableTesoro}
                              required
                            />
                            <label htmlFor="bancoTesoro">
                              <img 
                                alt="imagen debito" 
                                src={require('../../assets/img/tesoro.svg')}
                                height="60px" 
                              />
                            </label>
                            <input 
                              onChange={this.props.bancoDebito} 
                              value="activo" 
                              name="banco" 
                              type="radio" 
                              id="bancoActivo"
                            />
                            <label htmlFor="bancoActivo">
                              <img 
                                alt="imagen debito" 
                                src={require('../../assets/img/activo.svg')}
                                height="50px"
                              />
                            </label>
                            {/* <input 
                              onChange={this.props.bancoDebito} 
                              value="activo" 
                              name="banco" 
                              type="radio" 
                              id="bancoActivo"
                            />
                            <label htmlFor="bancoActivo">
                              <img 
                                alt="imagen debito" 
                                src={require('../../assets/img/activo.svg')}
                                height="50px"
                              />
                            </label> */}
                            <input 
                              onChange={this.props.bancoDebito} 
                              value="bod" 
                              name="banco" 
                              type="radio" 
                              id="BOD"
                            />
                            <label htmlFor="BOD">
                              <img 
                                alt="imagen debito" 
                                src={require('../../assets/img/bod.svg')}
                                height="50px"
                              />
                            </label>
                          </MDBRow>
                          <MDBRow className="colorConviasa caja py-2">
                            <MDBCol>
                              <MDBRow>
                                <strong className="h5 white-text ml-3">Introduzca su Cédula o RIF</strong>    
                              </MDBRow>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow>
                            {selectCedula}
                            <MDBCol md="6">
                              <MDBInput 
                                type="text"
                                maxLength="9"
                                pattern="[0-9]+"
                                label="Cédula o RIF" 
                                onChange={this.props.cedulaDebito} 
                                required
                              />
                            </MDBCol>
                          </MDBRow>
                        </div>

    } else if (this.state.TipoPago === 'pmc') {
      metodosdePago = <div className="formulario">
      <MDBRow className="colorConviasa caja py-2">
        <MDBCol>
          <MDBRow style={{justifyContent:"center"}}>
            <strong className="strong-subtitle">Datos del pagador pago móvil C2P</strong>    
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <MDBRow style={{justifyContent: 'center'}}>
        <span style={{fontSize: 25, fontWeight: 'bold', color:"black"}}>Bs. {this.parseNumbers1(this.props.monto_bs_view)}</span>
      </MDBRow>
      <br />
      {/* <MDBRow style={{textAlign: 'center'}} className="colorAccent caja py-2">
        <MDBCol>
          <img 
            alt="imagen debito" 
            src={require('../../assets/img/svpos.png')} 
            height="75px"
          />
        </MDBCol>
      </MDBRow> */}
      <MDBRow className="colorAccent caja py-2" style={{justifyContent:"center"}}>
        <MDBCol sm="1" xs="1" lg="1" xl="1" style={{marginTop:"33px"}}>
            <label className="icon-modal2"><MDBIcon icon="id-card"/></label>
        </MDBCol>
        <MDBCol className="label-modal2" sm="11" xs="11" lg="5" xl="5" md="5" style={{marginLeft:"-10px"}}>
            <MDBInput 
              label="Cédula o Pasaporte"
              size="sm" 
              onChange={this.props.cedula_conexus}
              required
              maxLength="11"
              pattern="[0-9]+"
            />
        </MDBCol>
        <MDBCol className="icon-modal2" sm="1" xs="1" lg="1" xl="1" style={{marginTop:"33px"}}>
            <label ><MDBIcon icon="mobile-alt"/></label>
        </MDBCol>
        <MDBCol className="label-modal2" sm="11" xs="11" lg="5" xl="5" md="5" style={{marginLeft:"-10px"}}>
            <MDBInput 
              label="Teléfono móvil"
              size="sm" 
              onChange={this.props.telefono_conexus}
              required
              maxLength="11"
              pattern="[0-9]+"
            />
        </MDBCol>
      </MDBRow>
      <MDBRow className="colorAccent caja py-2" style={{justifyContent:"center"}}>
        <MDBCol sm="12" lg="12" xs="12" md="12">
          <MDBRow>
            <MDBCol sm="11" lg="11" xs="11">
              <MDBContainer fluid style={{marginLeft: '0px'}} className="mt-0">
                  <label className="label-modal2"><MDBIcon icon="university"/>&nbsp;Banco</label>
                  <select 
                    onChange={this.props.banco_conexus}
                    required
                    style={{marginTop: '0px', marginLeft: '0px', width: '80%', fontSize:"14px"}}
                  >
                    <option value="">Seleccione el banco</option>
                    <option value="0134">0134 - BANESCO</option>
                    <option value="0105">0105 - MERCANTIL</option>
                    <option value="0102">0102 - BANCO DE VENEZUELA</option>
                    <option value="0108">0108 - PROVINCIAL</option>
                    <option value="0171">0171 - BANCO ACTIVO</option>
                    <option value="0172">0172 - BANCAMIGA BANCO UNIVERSAL</option>
                    <option value="0114">0114 - BANCARIBE</option>
                    <option value="0128">0128 - BANCO CARONI</option>
                    <option value="0163">0163 - BANCO DEL TESORO</option>
                    <option value="0115">0115 - BANCO EXTERIOR</option>
                    <option value="0191">0191 - BANCO NACIONAL DE CREDITO</option>
                    <option value="0116">0116 - BANCO OCCIDENTAL DE DESCUENTO</option>
                    <option value="0138">0138 - BANCO PLAZA</option>
                    <option value="0137">0137 - BANCO SOFITASA</option>
                    <option value="0168">0168 - BANCRECER</option>
                    <option value="0177">0177 - BANFANB</option>
                    <option value="0174">0174 - BANPLUS</option>
                    <option value="0175">0175 - BICENTENARIO</option>
                    <option value="0157">0157 - DELSUR</option>
                    <option value="0151">0151 - FONDO COMUN</option>
                    <option value="0169">0169 - MI BANCO</option>
                    <option value="0104">0104 - VENEZOLANO DE CREDITO</option>
                    <option value="0156">0156 - 100% BANCO</option>
                  </select>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        {/* <MDBContainer style={{justifyContent: 'center', backgroundColor: 'yellow'}}>
          <strong className="h5">Dispone de 1 hora para terminar la compra, debe pagar el monto total del boleto, no cierre esta ventana hasta culminar el proceso.</strong>    
        </MDBContainer> */}
        <MDBCol className="icon-modal2" sm="1" xs="1" lg="1" xl="1" style={{marginTop:"33px"}} >
          <label ><MDBIcon icon="lock"/></label>
        </MDBCol>
        <MDBCol className="label-modal2" sm="10" xs="10" lg="10" md="10">
          <MDBInput 
            size="sm"
            label="OTP-Token suministrada por su banco" 
            onChange={this.props.otp_conexus}
            required
            maxLength="9"
            type="password"
            pattern="[0-9]+" 
          />
        </MDBCol>
      </MDBRow>
    </div>                   
    } else if (this.state.TipoPago === 'pm') {
      metodosdePago = <div className="formulario">
      <MDBRow className="colorConviasa caja py-2">
        <MDBCol>
          <MDBRow style={{justifyContent:"center"}}>
            <strong className="strong-subtitle">Datos del pagador pago móvil P2C</strong>    
          </MDBRow>
        </MDBCol>
      </MDBRow>
      {/* <MDBRow style={{textAlign: 'center'}} className="colorAccent caja py-2">
        <MDBCol>
          <img 
            alt="imagen debito" 
            src={require('../../assets/img/svpos.png')} 
            height="75px"
          />
        </MDBCol>
      </MDBRow> */}
      <MDBRow className="colorAccent caja py-2">
        <MDBCol sm="12">
        <MDBRow style={{justifyContent: 'center'}}>
        <span style={{fontSize: 25, fontWeight: 'bold', color:"black"}}>Bs. {this.parseNumbers1(this.props.monto_bs_view)}</span>
        </MDBRow>
        <br />
          <MDBRow style={{justifyContent: 'center'}}>
            <span style={{fontSize: 18, fontWeight: 'bold', color:"black"}}>Datos del comercio</span>
          </MDBRow>
          <MDBRow style={{justifyContent: 'center'}}>
              <span style={{fontSize: 15, fontWeight: 'bold', color:"black"}}>Banco: &nbsp;
                <span style={{fontSize: 15, fontWeight: 'lighter', color:"black"}}>
                  Banco Activo
                </span>
              </span>
          </MDBRow>
          <MDBRow style={{justifyContent: 'center'}}>
              <span style={{fontSize: 15, fontWeight: 'bold', color:"black"}}>Teléfono: &nbsp;
                <span style={{fontSize: 15, fontWeight: 'lighter', color:"black"}}>
                  04242731677
                </span>
              </span>
          </MDBRow>
          <MDBRow style={{justifyContent: 'center'}}>
              <span style={{fontSize: 15, fontWeight: 'bold', color:"black"}}>RIF: &nbsp;
                <span style={{fontSize: 15, fontWeight: 'lighter', color:"black"}}>
                  J-299077127
                </span>
              </span>
          </MDBRow>
          <br/>
          <MDBContainer style={{justifyContent: 'center', backgroundColor: 'yellow', fontWeight:"bold"}}>
          <strong 
          className="h5" 
          style={{justifyContent: 'center', backgroundColor: 'yellow', fontWeight:"bold"}}
          >- Ingrese en la aplicación móvil o portal web de su banco, realice el pago con las indicaciones estipuladas y proceda a ingresar en el formulario el banco de origen y teléfono del pagador.
          </strong>    
          </MDBContainer>
          <hr style={{borderColor:"black"}}></hr>
        </MDBCol>
        <MDBCol sm="12" className="mt-1">
          <MDBRow style={{justifyContent: 'center'}}>
            <span style={{fontSize: 18, fontWeight: 'bold', color:"black", alignContent:"center", textAlign:"center"  }}>Datos del pago</span>
          </MDBRow>
          <MDBRow>
            <MDBCol sm="12">
              <MDBContainer fluid style={{marginLeft: '0px'}} className="mt-2">
                  <label className="label-modal2"><MDBIcon icon="university"/>&nbsp;Banco</label>
                  <select 
                    onChange={this.props.banco_pm}
                    required
                    style={{marginTop: '0px', marginLeft: '5px', width: '65%', fontSize:"14px"}}
                  >
                    <option value="">Seleccione el banco</option>
                    <option value="0134" >0134 - BANESCO</option>
                    <option value="0105" >0105 - MERCANTIL</option>
                    <option value="0102" >0102 - BANCO DE VENEZUELA</option>
                    <option value="0108" >0108 - PROVINCIAL</option>
                    <option value="0171" >0171 - BANCO ACTIVO</option>
                    <option value="0172" >0172 - BANCAMIGA BANCO UNIVERSAL</option>
                    <option value="0114" >0114 - BANCARIBE</option>
                    <option value="0128" >0128 - BANCO CARONI</option>
                    <option value="0163" >0163 - BANCO DEL TESORO</option>
                    <option value="0115" >0115 - BANCO EXTERIOR</option>
                    <option value="0191" >0191 - BANCO NACIONAL DE CREDITO</option>
                    <option value="0116" >0116 - BANCO OCCIDENTAL DE DESCUENTO</option>
                    <option value="0138" >0138 - BANCO PLAZA</option>
                    <option value="0137" >0137 - BANCO SOFITASA</option>
                    <option value="0168" >0168 - BANCRECER</option>
                    <option value="0177" >0177 - BANFANB</option>
                    <option value="0174" >0174 - BANPLUS</option>
                    <option value="0175" >0175 - BICENTENARIO</option>
                    <option value="0157" >0157 - DELSUR</option>
                    <option value="0151" >0151 - FONDO COMUN</option>
                    <option value="0169" >0169 - MI BANCO</option>
                    <option value="0104" >0104 - VENEZOLANO DE CREDITO</option>
                    <option value="0156" >0156 - 100% BANCO</option>
                  </select>
              </MDBContainer>
            </MDBCol>
            <MDBCol className="icon-modal2" sm="1" xs="1" lg="1" xl="1" style={{marginTop:"38px", marginRight:"0px"}}>
              <label><MDBIcon icon="mobile-alt"/></label>
            </MDBCol>
            <MDBCol className="label-modal2" sm="10" xs="10" lg="10" xl="10">
              <MDBInput
                label="Teléfono móvil"
                size="sm" 
                onChange={this.props.telefono_pm}
                required
                maxLength="11"
                pattern="[0-9]+"
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        {/* <MDBContainer style={{justifyContent: 'center', backgroundColor: 'yellow'}}>
          <strong className="h5">Dispone de 1 hora para terminar la compra, debe pagar el monto total del boleto, no cierre esta ventana hasta culminar el proceso.</strong>    
        </MDBContainer> */}
      </MDBRow>
    </div>                   
    } else if (this.state.TipoPago === 'trf') {
      metodosdePago = <div className="colorAccent">
                        <MDBCol>
                          <MDBRow center id="secondary" >
                            <div>
                              <MDBIcon icon="info-circle" /> Por los momentos solo serán procesadas transferencias 
                              desde los bancos listados al Banco del Tesoro. Si usted 
                              realiza la transferencia desde otro banco no listado, no será procesada.
                            </div>
                            <div>
                              <MDBIcon icon="info-circle" />Para soporte o requerimientos: ingresar en el portal web en la pestaña "Atención al Pasajero".
                            </div>
                          </MDBRow>
                          <MDBRow id="accent">
                            <div className="h5">Instrucciones de pago</div>
                          </MDBRow>
                          <MDBRow id="accent">
                            <MDBCol 
                              left='true' 
                              sm="12" 
                              xl="6"  
                              className="my-3"
                            >
                              <div className="h5 text-justify" >
                                <span className="h3">1) </span> Solo transferir el monto completo desde el:
                              </div>
                              <div className="text-justify h6">
                                <div>
                                  BANESCO BANCO UNIVERSAL, C.A.
                                </div>
                                <div>
                                  BANCO DE VENEZUELA, S.A. BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCO NACIONAL DE CREDITO C.A
                                </div>
                                <div>
                                  BANCO PROVINCIAL, S.A BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCO ACTIVO, BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANPLUS ENTIDAD DE AHORRO Y PRESTAMO C.A
                                </div>
                                <div>
                                  BANCO PLAZA, C.A
                                </div>
                                <div>
                                  BANCO MERCANTIL, C.A., BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCO FONDOCOMUN, C.A., BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCARIBE C.A
                                </div>
                                <div>
                                  BANPLUS BANCO UNIVERSAL
                                </div>
                                <div>
                                  VENEZOLANO DE CREDITO S.A. BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCO EXTERIOR, C.A., BANCO UNIVERSAL
                                </div>
                                <div>
                                  BANCAMIGA BANCO UNIVERSAL
                                </div>
                              </div>
                            </MDBCol>
                            <HideAt breakpoint="largeAndBelow">
                              <MDBCol 
                                sm="12" 
                                xl="6"  
                                className="my-3 text-justify"
                              >
                                <span className="h3">3) </span>
                                <div className="h6">
                                  La emisión del boleto se hará efectiva el día hábil siguiente después de las 8:00pm.
                                  RECUERDA QUE TIENES DOS (02) HORAS PARA REALIZAR LA TRANSFERENCIA Y COMPLETAR LA COMPRA.
                                </div>
                              </MDBCol>
                              <MDBCol 
                                sm="12" 
                                xl="6" 
                                className="text-justify"
                              >
                                <div className="h6">
                                  <span className="h3">2) </span>
                                  Las instrucciones detalladas serán enviadas al correo electrónico ingresado por el pagador.
                                </div>
                              </MDBCol>
                            </HideAt>
                            <ShowAt breakpoint="largeAndBelow">
                              <MDBCol 
                                sm="12" 
                                xl="6" 
                                className="text-justify"
                              >
                                <div className="h6">
                                  <span className="h3">2) </span>
                                  Las instrucciones detalladas serán enviadas al correo electrónico ingresado por el pagador.
                                </div>
                              </MDBCol>
                              <MDBCol 
                                sm="12" 
                                xl="6"  
                                className="my-3 text-justify"
                              >
                                <div className="h6">
                                  <span className="h3">3) </span>
                                  La emisión del boleto se hará efectiva el día hábil siguiente después de las 8:00pm.
                                  RECUERDA QUE TIENES DOS (02) HORAS PARA REALIZAR LA TRANSFERENCIA Y COMPLETAR LA COMPRA.
                                </div>
                              </MDBCol>
                            </ShowAt>
                            <MDBCol 
                              sm="12" 
                              xl="6" 
                              className="text-justify"
                            >
                              <div className="h6">
                                <span className="h3">4) </span>
                                Para completar la transaccón debe oprimir el botón "Continuar".
                              </div>
                            </MDBCol>
                          </MDBRow>
                        </MDBCol>
                      </div>
    } else if (this.state.TipoPago === 'tdcp') {
      metodosdePago = <div>
                        <MDBRow>
                          <MDBCol className="colorAccent caja">
                            <MDBRow className="colorConviasa py-4">
                              <MDBCol>
                                <MDBRow >
                                  <strong className="h6 ml-3" style={{color: '#000', fontWeight:"bold"}}>Introduzca los datos de su tarjeta de crédito internacional</strong>    
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                              <MDBRow style={{justifyContent: 'center'}}>
                                <span style={{fontSize: 25, fontWeight: 'bold', color:"black"}}>USD {this.parseNumbers1(this.props.monto_usd_view)}</span>
                              </MDBRow>
                            <MDBRow className="colorAccent" style={{fontWeight:"bold"}}>
                              <input style={{fontWeight:"bold"}} type="hidden" value={bb.blackbox} />
                              <MDBCol md="12" lg="6">
                                <MDBInput
                                  style={{fontWeight:"bold"}}
                                  type="text" 
                                  label="Nombre Titular de la Tarjeta" 
                                  onChange={this.props.nombreTTarjetaC} 
                                  required
                                  pattern="[a-zA-Z]+"
                                />
                              </MDBCol>
                              <MDBCol md="12" lg="6">
                                <MDBInput
                                  style={{fontWeight:"bold"}}
                                  type="text" 
                                  label="Apellido Titular de la Tarjeta" 
                                  // onChange={this.props.nombreTTarjetaC} 
                                  required
                                  pattern="[a-zA-Z]+"
                                />
                              </MDBCol>
                              <MDBCol md="12" lg="6">
                                <MDBInput
                                  type="text" 
                                  label="N° de Tarjeta" 
                                  onChange={this.props.nroTTarjetaC} 
                                  required
                                  maxLength="16"
                                  pattern="[0-9]+"
                                />
                              </MDBCol>
                              {/* <MDBCol md="6">
                                <MDBInput 
                                  style={{fontWeight:"bold"}}
                                  type="email" 
                                  label="Email del Titular" 
                                  onChange={this.props.emailTTarjetaC} 
                                  required
                                />
                              </MDBCol> */}
                            </MDBRow>
                            <MDBRow className="colorAccent" style={{fontWeight:"bold"}}>
                              <MDBCol md="6">
                                <MDBInput
                                  style={{fontWeight:"bold"}} 
                                  type="text" 
                                  label="Cédula o Pasaporte" 
                                  onChange={this.props.cedulaTTarjetaC} 
                                  required
                                  maxLength="9"
                                  pattern="[0-9]+"
                                />
                              </MDBCol>

                              <MDBCol md="6"style={{fontWeight:"bold"}} >
                                <MDBInput
                                  type="text" 
                                  label="CCV2" 
                                  onChange={this.props.codigoSTTarjetaC} 
                                  required
                                  maxLength="3"
                                  pattern="[0-9]+"
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="colorAccent">
                              <MDBCol md="6">
                                <MDBInputGroup
                                  containerClassName="mt-4"
                                  inputs={
                                    <select style={{fontSize:"12px"}}  defaultValue="0" className="browser-default custom-select" onChange={this.props.mesVTarjetaC}>
                                      <option style={{fontWeight:"bold"}} disabled value="0">Seleccione Mes de Vencimiento</option>
                                      <option style={{fontWeight:"bold"}} value="01">01</option>
                                      <option style={{fontWeight:"bold"}} value="02">02</option>
                                      <option style={{fontWeight:"bold"}} value="03">03</option>
                                      <option style={{fontWeight:"bold"}} value="04">04</option>
                                      <option style={{fontWeight:"bold"}} value="05">05</option>
                                      <option style={{fontWeight:"bold"}} value="06">06</option>
                                      <option style={{fontWeight:"bold"}} value="07">07</option>
                                      <option style={{fontWeight:"bold"}} value="08">08</option>
                                      <option style={{fontWeight:"bold"}} value="09">09</option>
                                      <option style={{fontWeight:"bold"}} value="10">10</option>
                                      <option style={{fontWeight:"bold"}} value="11">11</option>
                                      <option style={{fontWeight:"bold"}} value="12">12</option>
                                    </select>
                                  }
                                />
                              </MDBCol>
                              <MDBCol md="6" className="mb-4">
                                <MDBInputGroup
                                  containerClassName="mt-4"
                                  inputs={
                                    <select style={{fontSize:"12px"}}  defaultValue="0" className="browser-default custom-select" onChange={this.props.anioVTarjetaC} required>
                                      <option style={{fontWeight:"bold"}} disabled value="0">Seleccione Año de Vencimiento</option>
                                      <option style={{fontWeight:"bold"}} value="20">2020</option>
                                      <option style={{fontWeight:"bold"}} value="21">2021</option>
                                      <option style={{fontWeight:"bold"}} value="22">2022</option>
                                      <option style={{fontWeight:"bold"}} value="23">2023</option>
                                      <option style={{fontWeight:"bold"}} value="24">2024</option>
                                      <option style={{fontWeight:"bold"}} value="25">2025</option>
                                      <option style={{fontWeight:"bold"}} value="26">2026</option>
                                    </select>
                                  }
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="colorAccent">
                              {/* <MDBCol md="3">
                                <MDBInputGroup
                                  containerClassName="mt-4"
                                  inputs={
                                    <select defaultValue="0" className="browser-default custom-select" onChange={this.props.paisETarjetaC} required>
                                      <option disabled value="0">Seleccione País emisor de la tarjeta</option>
                                      {this.state.datosPaises.map((element, i) => {
                                        return <option key={i} value={element.country_code}>
                                          {element.name}
                                        </option>
                                      })}
                                    </select>
                                  }
                                />
                              </MDBCol> */}
                              {/* <MDBCol md="3">
                                <MDBInputGroup
                                  containerClassName="mt-4"
                                  inputs={
                                    <select defaultValue="0" className="browser-default custom-select" onChange={this.props.codTTarjetaC} required>
                                      <option disabled value="0">Seleccione Código telefonico del país</option>
                                      <option value="58">Venezuela</option>
                                      <option value="2">Two</option>
                                      <option value="3">Three</option>
                                    </select>
                                  }
                                />
                              </MDBCol> */}
                              {/* <MDBCol md="3">
                                <MDBInput 
                                  type="number" 
                                  label={this.props.codArea}
                                  disabled
                                />
                              </MDBCol> */}
                              {/* <MDBCol md="3">
                                <MDBInput 
                                  type="number" 
                                  label="N° de Teléfono" 
                                  onChange={this.props.codNroTarjetaC} 
                                  required
                                />
                              </MDBCol> */}
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </div>
    } else {
      metodosdePago = <div></div>
    }

    //botones de pago condicionales
    let botonesDePago
    if (this.props.scope === 'I') {
      // if (this.props.currency === 'USD') {
      //   botonesDePago = 
      //   <MDBRow start className="colorAccent caja radio radioMetodoPago">
      //     {/* <input 
      //       onChange={this.cambiarMetodo} 
      //       value="tdcp" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="CrediCorp"
      //     />
      //     <label htmlFor="CrediCorp">
      //       <img 
      //         alt="imagen credito" 
      //         src={metroBank} 
      //         height="65px"
      //       />
      //     </label> */}
      //     <input 
      //       onChange={this.toggleCredito} 
      //       value="tdce" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="credito"
      //     />
      //     <label htmlFor="credito">
      //       <img 
      //         alt="imagen credito" 
      //         src={credito} 
      //         height="65px"
      //       />
      //     </label>
      //     <input 
      //       onChange={this.togglePetro} 
      //       value="petro" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="petro"
      //     />
      //     <label htmlFor="petro">
      //       <img 
      //         alt="petro" 
      //         src={petro} 
      //         height="55px"
      //       />
      //     </label>
      //     <input 
      //       onChange={this.cambiarMetodo} 
      //       value="reserva" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="reserva"
      //     />
      //     <label htmlFor="reserva">
      //       <img 
      //         alt="petro" 
      //         src={reserva} 
      //         height="55px"
      //       />
      //     </label>
      //   </MDBRow> 
      // } else {
      //   botonesDePago =
      //   <MDBRow start className="pt-2 colorAccent caja radio radioMetodoPago">
      //     <input 
      //       onChange={this.cambiarMetodo} 
      //       value="tdcp" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="CrediCorp"
      //     />
      //     <label htmlFor="CrediCorp">
      //       <img 
      //         alt="imagen credito" 
      //         src={require('../../assets/img/visa.svg')} 
      //         height="65px"
      //       />
      //     </label>
      //     {/* <input 
      //       onChange={this.cambiarMetodo} 
      //       value="tdd" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="pfdebito"
      //     />
      //     <label htmlFor="pfdebito">
      //       <img 
      //         alt="imagen debito" 
      //         src={pftdd} 
      //         height="55px"
      //       />
      //     </label> */}
      //     <input 
      //       onChange={this.toggle} 
      //       value="pm" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="pfpm"
      //       disabled={true}
      //     />
      //     <label htmlFor="pfpm">
      //       <img 
      //         alt="imagen transferencia" 
      //         src={pfpm} 
      //         height="55px"
      //       />
      //     </label>
      //     {/* <input
      //       onChange={this.cambiarMetodo} 
      //       value="trf" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="pftrf"
      //       disabled={true}
      //       // disabled={this.canTrf()}
      //     />
      //     <label htmlFor="pftrf">
      //       <img
      //         alt="imagen transferencia" 
      //         src={pftrf} 
      //         height="55px"
      //       />
      //     </label> */}
      //     <input 
      //       onChange={this.togglePetro} 
      //       value="petro" 
      //       name="metodoPago" 
      //       type="radio" 
      //       id="petro"
      //     />
      //     <label htmlFor="petro">
      //       <img 
      //         alt="petro" 
      //         src={petro} 
      //         height="55px"
      //       />
      //     </label>
      //   </MDBRow>
      // }
    } else if (this.state.metodosPago === 'N') {
      if (this.props.coin_metodo_pago === "USD") {
        botonesDePago =
      <MDBRow start className="pt-2 colorAccent caja radio radioMetodoPago">
        <input 
            // onChange={this.cambiarMetodo} 
            onChange={this.proximamente} 
            value="tdcp" 
            name="metodoPago" 
            type="radio" 
            id="CrediCorp"
          />
          <label htmlFor="CrediCorp">
            <img 
              alt="imagen credito" 
              src={require('../../assets/img/visa.svg')} 
              height="30px"
            />
          </label>
      </MDBRow>
      } else if (this.props.coin_metodo_pago === "BS") {
      botonesDePago =
      <MDBRow start className="pt-2 colorAccent caja radio radioMetodoPago">
        {/* <input 
          onChange={this.cambiarMetodo} 
          value="tdd" 
          name="metodoPago" 
          type="radio" 
          id="pfdebito"
        /> */}
        {/* <label htmlFor="pfdebito"> */}
          {/* <MDBRow>
            <MDBCol> */}
                {/* <span style={{fontSize: 37, fontWeight: 'bold'}}> */}
                  {/* <img 
                    alt="imagen debito" 
                    src={require('../../assets/img/svpos.png')} 
                    height="55px"
                  /> */}
                  {/* &nbsp; */}
                  {/* Débito */}
                {/* </span>  */}
            {/* </MDBCol>
          </MDBRow> */}
        {/* </label> */}
        <input 
          onChange={this.cambiarMetodo} 
          value="pm" 
          name="metodoPago" 
          type="radio" 
          id="pm"
        />
        <label htmlFor="pm" style={{color:"black"}}>
        <MDBRow>
            <MDBCol>
                {/* <span className="span-pagos-opciones"> */}
                  <img 
                    alt="imagen pm" 
                    src={require('../../assets/img/activo.svg')} 
                    height="25px"
                  /> 
                   &nbsp;
                  Pago Móvil P2C
               {/* </span> */}
            </MDBCol>
          </MDBRow>
        </label>
        {/* <input 
          onChange={this.cambiarMetodo} 
          value="pmc" 
          name="metodoPago" 
          type="radio" 
          id="pmconexus" */}
        {/* /> */}
        {/* <label htmlFor="pmconexus">
        <MDBRow>
            <MDBCol>
                <span className="span-pagos-opciones"> */}
                  {/* <img 
                    alt="imagen pm" 
                    src={require('../../assets/img/conexus.png')} 
                    height="55px"
                  />
                  &nbsp; */}
                  {/* Pago Móvil C2P */}
                {/* </span> 
            </MDBCol>
          </MDBRow>
        </label> */}
        {/* <input 
          onChange={this.cambiarMetodo} 
          value="zelle" 
          name="metodoPago" 
          type="radio" 
          id="zelle"
          disabled={true}
        />
        <label htmlFor="zelle">
          <img 
            alt="imagen zelee" 
            src={zelle_logo} 
            height="55px"
          />
        </label> */}
        {/* <input 
          onClick={this.toggleAvisoPM} 
          value="pm" 
          name="metodoPago" 
          type="radio" 
          id="pfpm"
        />
        <label htmlFor="pfpm">
              <span style={{fontSize: 37, fontWeight: 'bold'}}>Pago Móvil</span> 
        </label> */}
        {/* <input
          onChange={this.cambiarMetodo} 
          value="trf" 
          name="metodoPago" 
          type="radio" 
          id="pftrf"
          //deshabilitar colocando disabled={true}
          disabled={false}
          // disabled={this.canTrf()}
        />
        <label htmlFor="pftrf">
          <img
            alt="imagen transferencia" 
            src={pftrf} 
            height="55px"
          />
        </label> */}
        {/* <input 
          onChange={this.togglePetro} 
          value="petro" 
          name="metodoPago" 
          type="radio" 
          id="petro"
        />
        <label htmlFor="petro">
          <img 
            alt="petro" 
            src={petro} 
            height="55px"
          />
        </label> */}
      </MDBRow>
      }
    }

    return (
      <div>
        <div className="formulario">
          {botonesDePago}
        </div>
        {metodosdePago}
        <MDBModal isOpen={this.state.modalAvisoPM} centered>
          <MDBModalHeader className="colorConviasa" style={{color: '#000'}}>
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="colorAccent h6">
            {/* <p className="h6 red-text">
              Atención <br />
              - Estimado usuario dispones de una (01) hora para realizar el pago.
            </p>
            <p className="h6 red-text">
              - La fecha del Pago Móvil a registrar debe coincidir con la fecha cuando compraste el boleto.
            </p>
            <p className="h6 red-text">
              - En caso de algún inconveniente mientras realizas el pago, comunícate con Conviasa al 
                0281-5201400 o ingrese en el portal web en la pestaña "Atención al Pasajero".
            </p>
            <p className="h6 red-text">
              - Estimado Usuario, solo se aceptaran pagos de los bancos listados acontinuación:  
            </p> */}
            <div className="h6 black-text">
              <MDBRow>
                <MDBCol>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANESCO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> PROVINCIAL
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> VENEZUELA
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCAMIGA
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> EXTERIOR
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> ACTIVO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> FONDOCOMUN
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCO NACIONAL DE CREDITO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> VENEZOLANO DE CREDITO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCO OCCIDENTAL DE DESCUENTO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> 100% BANCO
                  </p>
                </MDBCol>
                <MDBCol>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANFANB
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCRECER
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> DELSUR
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCARIBE
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> CARONI
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> SOFITASA
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> PLAZA
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> AGRICOLA
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BICENTENARIO
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> BANCO MERCANTIL.
                  </p>
                  <p>
                    <i className="fas fa-circle fa-xs"></i> MI BANCO
                  </p>
                </MDBCol>
              </MDBRow>
            </div>
            <p className="h6 red-text">
              Por favor revisar antes de realizar el pago.
            </p>
          </MDBModalBody>
          <div className="formulario">
            <MDBModalBody className="colorAccent radio radioMetodoPago">
              <input
                onChange={this.activarRadioAvisoPM} 
                value="rpm"
                name="aceptarPoliticasPM" 
                type="radio" 
                id="politicasPM"
              />
              <label htmlFor="politicasPM">
                Entiendo y acepto las condiciones para utilizar el servicio de PagoMovil
              </label>
            </MDBModalBody>
          </div>
          <div className="colorConviasa">
            <MDBBtn 
              disabled={this.state.radioAvisoPM}
              onClick={this.handleAvisoPago} 
              className="white-text" 
              color="green"
              flat
              block
            >
              <span style={{color: '#000'}}>
                Continuar
              </span>
            </MDBBtn>
          </div>
          
        </MDBModal>
        <MDBModal isOpen={this.state.modal} centered>
          <MDBModalHeader className="colorConviasa white-text" >
            <div className="h4 text-center" style={{color: '#000'}}>
              INSTRUCCIONES PARA REALIZAR EL PAGO MOVIL
            </div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center className="h5">
              Datos Pagomovil
            </MDBRow>
            <hr className="colorConviasa" />
            <MDBRow center>
              <ShowAt breakpoint="mediumAndAbove">
                <MDBCol className="h6" sm="3">
                  <img
                    height="40px" 
                    alt="Isologo banco activo" 
                    src={isologoActivo} 
                  /><br />
                </MDBCol>
              </ShowAt>
              <ShowAt breakpoint="small">
                <MDBRow className="h6" sm="3">
                  <img
                    height="40px" 
                    alt="Isologo banco activo" 
                    src={isologoActivo} 
                  /> <br />
                </MDBRow>
              </ShowAt>
            </MDBRow>
            <MDBRow>
              <MDBCol sm="12">
                <p className="h5 text-center">Lee las instrucciones antes de continuar:</p>
              </MDBCol>
            </MDBRow>
            <MDBRow className="text-justify">
              <MDBCol sm="12">
                <p className="h6">
                  PASO 1: Toma nota del monto total a pagar correspondiente a tu reservación. 
                          Recuerda que solo aceptamos pagos móviles por el monto total de la reserva.
                </p>
                <p className="h6">
                  PASO 2: Ingresa a P2P - Pago Móvil Interbancario de tu Banco, desde el celular o PC, 
                  para que realices el pago a RUTACA con los siguientes datos:
                </p>
                <p className="h6">PASO 3: Registra los datos del pago realizado en el siguiente formulario:
                </p>
              </MDBCol>
              <MDBModalBody className="colorConviasa">
                <MDBRow className="h5 text-center" style={{color: '#000'}} sm="9" center>
                  <hr className="white" />
                    RIF: J-09500396-5<br />
                    Teléfono Receptor: 0426-5118982<br/>
                    Banco Receptor: ACTIVO<br/>
                    {/* Teléfono: 0414-2634243 */}
                  <hr className="white" />
                </MDBRow>
              </MDBModalBody>
              <MDBModalBody className="colorConviasa mt-3">
                <MDBRow className="h5" sm="9" center>
                  <p style={{color: '#000'}}>MONTO A PAGAR: Bs. {this.props.montoMuestra}<br/></p>
                </MDBRow>
              </MDBModalBody>
            </MDBRow>
          </MDBModalBody>
          <div className="colorConviasa">
            <hr className="white" />
            <MDBRow center className="h6" style={{color: '#000'}}>
              REGISTRO DEL PAGO MOVIL REALIZADO
            </MDBRow>
            <hr className="white" />
          </div>
          <MDBModalBody>
            <MDBRow className="white" center>
              <MDBCol size="12">
                <MDBInputGroup 
                  name='bancoPM'
                  containerClassName="mt-2"
                  onChange={this.props.bancoPM}
                  inputs={
                    <select required className="browser-default custom-select">
                      <option value="">Seleccione banco</option>
                      <option value="0134">BANESCO BANCO UNIVERSAL, C.A.</option>
                      <option value="0108">BANCO PROVINCIAL, S.A BANCO UNIVERSAL</option>
                      <option value="0163">BANCO DEL TESORO</option>
                      <option value="0102">BANCO DE VENEZUELA, S.A. BANCO UNIVERSAL</option>
                      <option value="0174">BANPLUS BANCO UNIVERSAL</option>
                      <option value="0172">BANCAMIGA BANCO UNIVERSAL</option>
                      <option value="0115">BANCO EXTERIOR, C.A., BANCO UNIVERSAL</option>
                      <option value="0171">BANCO ACTIVO, BANCO UNIVERSAL</option>
                      <option value="0151">BANCO FONDOCOMUN, C.A., BANCO UNIVERSAL</option>
                      <option value="0191">BANCO NACIONAL DE CREDITO C.A</option>
                      <option value="0104">VENEZOLANO DE CREDITO S.A. BANCO UNIVERSAL</option>
                      <option value="0116">BANCO OCCIDENTAL DE DESCUENTO BANCO UNIVERSAL</option>
                      <option value="0156">100% BANCO, BANCO UNIVERSAL, C.A. </option>
                      <option value="0177">BANFANB</option>
                      <option value="0168">BANCRECER S.A. BANCO DE DESARROLLO</option>
                      <option value="0157">DELSUR BANCO UNIVERSAL</option>
                      <option value="0114">BANCARIBE C.A</option>
                      <option value="0128">BANCO CARONI, C.A BANCO UNIVERSAL</option>
                      <option value="0137">SOFITASA</option>
                      <option value="0138">BANCO PLAZA, C.A</option>
                      <option value="0166">BANCO AGRICOLA DE VENEZUELA</option> 
                      <option value="0175">BICENTENARIO BANCO UNIVERSAL</option>
                      <option value="0105">BANCO MERCANTIL, C.A., BANCO UNIVERSAL</option>
                      <option value="0169">MI BANCO, BANCO MICROFINANCIERO, C.A</option>
                    </select>
                  }
                />
              </MDBCol>
              {/* <MDBCol size="4" md="2" className="mt-4">
                <select required className="browser-default custom-select" onChange={this.props.codCedulaPM}>
                  <option value="">-</option>
                  <option value="V">V</option>
                  <option value="E">E</option>
                </select>
              </MDBCol>
              <MDBCol size="8" md="10">
                <MDBInput 
                  type="text"
                  maxLength="8"
                  pattern="[0-9]+"
                  label="Cédula"
                  onChange={this.props.cedulaPM}
                  required
                />
              </MDBCol> */}
              <MDBCol size="12">
              <MDBRow>
                <p className="yellow ml-3 mt-3 py-2 px-1 h6">
                  <MDBIcon icon="exclamation" className="mr-2 red-text" />Nota: Estimado cliente, el número telefonico a ingresar debe ser el mismo registrado en su banco.
                </p>
              </MDBRow>
                <MDBRow>
                  <MDBCol md="6" className="mt-4">
                    <MDBInputGroup 
                      name='bancoPM'
                      containerClassName="mt-2"
                      onChange={this.props.codAreaTlfPM}
                      inputs={
                        <select required className="browser-default custom-select">
                          <option value="">Cod. Telefonico</option>
                          <option value="414">414</option>
                          <option value="424">424</option>
                          <option value="426">426</option>
                          <option value="412">412</option>
                          <option value="416">416</option>
                        </select>
                      }
                      />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBInput
                      type="text" 
                      maxLength="7"
                      pattern="[0-9]+"
                      label="Teléfono"
                      onChange={this.props.nroTelefonoPM}
                      required
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              {/* <MDBCol size="12">
                <MDBInput 
                  type="email"
                  label="Correo"
                  onChange={this.props.emailPM}
                  required
                />
              </MDBCol> */}
              {/* <MDBCol size="12">
                <MDBInput 
                  type="text"
                  pattern="[0-9]+"
                  maxLength="12"
                  label="Referencia"
                  onChange={this.props.codReferenciaPM}
                  required
                />
              </MDBCol> */}
            </MDBRow>
          </MDBModalBody>
          <MDBRow center className="mb-2">
            <div className="mx-1">
              <MDBBtn 
                className="boton-modal-1-aceptar" 
                onClick={this.toggle}
              >
                Cancelar
              </MDBBtn>
            </div>
            <div className="mx-1">
              <MDBBtn 
                color="green"
                className="white-text"
                type="submit"
                disabled={this.props.spinner}
                >
                  {spinner}
                </MDBBtn>
            </div>
          </MDBRow>
        </MDBModal>
        <MDBModal isOpen={this.state.modalCredito} centered>
          <MDBModalHeader className="colorConviasa white-text">
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="colorAccent h6">
            UD. HA SELECCIONADO PAGO CON TARJETA DE CRÉDITO. LE COMUNICAMOS QUE AL ACEPTAR ESTA OPCIÓN SERÁ REDIRIGIDO 
            A LA PLATAFORMA DE PAGOS DE NUESTRO ALIADO COMERCIAL. 
            EL CARGO A SU TARJETA DE CRÉDITO POR EL IMPORTE DE SU BOLETO SERÁ REALIZADO POR DICHO ALIADO COMERCIAL
          </MDBModalBody>
          <div className="formulario">
            <MDBModalBody className="colorAccent radio radioMetodoPago">
              <input
                onChange={this.activarBotonCredito} 
                value="tdce"
                name="aceptarPoliticas" 
                type="radio" 
                id="politicas"
              />
              <label htmlFor="politicas">
                Entiendo que el cargo por 
                el importe de los boletos reservados será realizado por nuestro aliado comercial.
              </label>
            </MDBModalBody>
          </div>
          <form 
            id="TheForm" 
            method="post" 
            action="https://kiu.surnet.io:8081/GetPublicBtn" 
            target="TheWindow"
          >
            <MDBModalBody>
              <MDBRow center>
                <div md="4" className="colorConviasa mx-1">
                  <MDBBtn 
                    onClick={this.toggleCredito} 
                    flat 
                    className="white-text"
                    color="green"
                  >
                    Salir
                  </MDBBtn>
                </div>
                <div className="colorConviasa mx-1">
                  <input 
                    type="hidden" 
                    name="Amount" 
                    // value={this.props.total.toString()}
                  />
                  <input 
                    type="hidden" 
                    name="Localizador" 
                    // value={this.props.airBook.BookingReference.Id}
                  />
                  <input 
                    type="hidden" 
                    name="email" 
                    // value={this.props.airBook.TravelInfos[0].Email}
                  />
                  <MDBBtn 
                    disabled={this.state.botonCredito} 
                    onClick={this.activarCredito} 
                    className="white-text"
                    color="green"
                    flat
                  >
                    Continuar
                  </MDBBtn>
                </div>
              </MDBRow>
            </MDBModalBody>
          </form>
        </MDBModal>
        <MDBModal isOpen={this.state.modalPetro} centered>
          <MDBModalHeader className="colorConviasa white-text">
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="colorAccent h6">
            UD. HA SELECCIONADO PAGO CON PETRO. LE COMUNICAMOS QUE AL ACEPTAR ESTA OPCIÓN SERÁ REDIRIGIDO 
            A LA PLATAFORMA DE PAGOS: PETROPAGO. 
            EL CARGO A SU WALLET POR EL IMPORTE DE SU BOLETO SERÁ REALIZADO POR DICHA PLATAFORMA
          </MDBModalBody>
          <div>
            <MDBModalBody className="colorAccent radio radioMetodoPago">
              <MDBRow>
                <p>Por favor, introduzca el correo electrónico donde recibirá los boletos emitidos:</p>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput 
                    type="email" 
                    name="correoPetro"
                    label="E-mail" 
                    onChange={this.handdleCorreoPetro}
                    required
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBInput 
                    type="text" 
                    name="correoPetroC"
                    label="Confirmar E-mail" 
                    onChange={this.handdleCorreoPetroC}
                    required
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
          </div>
          <form 
            id="TheForm" 
            method="post"
            onSubmit={this.activarPetro} 
          >
            <MDBModalBody>
              <MDBRow center>
                <div md="4" className="colorConviasa mx-1">
                  <MDBBtn 
                    onClick={this.togglePetro} 
                    flat 
                    className="white-text"
                    color="green"
                  >
                    Salir
                  </MDBBtn>
                </div>
                <div className="colorConviasa mx-1">
                  <MDBBtn
                    className="white-text"
                    type="submit"
                    flat
                    color="green"
                  >
                    {spinnerPetro}
                  </MDBBtn>
                </div>
              </MDBRow>
            </MDBModalBody>
          </form>
        </MDBModal>
        <MDBModal isOpen={this.state.modalMantenimiento} centered>
          <MDBModalHeader className="colorConviasa white-text">
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="colorAccent h6">
            Estimado usuario estamos realizando labores de mantenimiento, por favor, seleccione otro metodo de pago muchas gracias.
          </MDBModalBody>
          <MDBModalBody>
            <MDBRow center>
              <div className="colorConviasa mx-1">
                <MDBBtn 
                  onClick={this.modalMantenimientoTrf} 
                  className="white-text" 
                  flat
                  color="green"
                >
                  Aceptar
                </MDBBtn>
              </div>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <MDBModal isOpen={this.state.modalAvisoSesion} centered>
          <MDBModalHeader className="colorConviasa white-text">
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="colorAccent h6">
            Estimado usuario el tiempo para realizar la transaccion a concluido, Ud. será redirigido a la pagina principal de conviasa.
          </MDBModalBody>
          <MDBModalBody>
            <MDBRow center>
              <div className="colorConviasa mx-1">
                <MDBBtn 
                  onClick={this.salir} 
                  className="white-text" 
                  flat
                  color="green"
                >
                  Aceptar
                </MDBBtn>
              </div>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
        <ModalErrores 
          toggleModalError={this.toggleModalError}
          modalErrores={this.state.modalErrores} 
          mensajeError={this.state.mensajeError} 
          codigoError={this.state.codError} 
        />
        <MDBModal className="py-1" isOpen={this.state.modal_proximamente}>
          <MDBModalHeader className="colorConviasa" style={{color:"black"}}>
            AVISO
          </MDBModalHeader>
          <MDBModalBody className="h5" style={{fontWeight: "bold", alingSelf: "center", textAling: "center", textAlignLast:"center"}}>
            Proximamente
          </MDBModalBody>
          <MDBModalBody className="colorConviasa" >
            <MDBRow xs="12" sm="12"  lg="12" md="12" xl="12" style={{color:"snow", justifyContent:"center"}}>
              <MDBBtn color="success"  onClick={this.toggleProximamente}  style={{borderColor: "green", radius:"0px", backgroundColor:"green"}}>Aceptar</MDBBtn>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

      </div>
    )
  }
}

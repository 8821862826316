import React, { Component } from 'react'
import {  
  MDBRow, 
  MDBCol, 
  MDBBtn, 
  MDBIcon, 
  // MDBCollapse,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBContainer
} from 'mdbreact'
import "../../assets/css/index.css"
import { SelectorPagos } from './FormularioPagos'
import axios from 'axios'
// import tokenKiu from '../../helpers/tokens'
//pagina de retorno
// import returnPage from '../../helpers/returnPage.js'

//modal para errores
import ModalErrores from '../../ModalError/ModalErrores'
import banned_tdc from '../../helpers/prohibited_cc.min'
// import ModalResumen from '../ModalResumen/ModalResumen'

//ruta kiu
// import ruta from '../../helpers/direccionKiu'

//imports de moment.js
import 'moment/locale/es-us';
const moment = require('moment');
moment.locale('es-us')

class index extends Component {
  constructor(props){
    super(props);
    // this.taxes = this.props.datosAirPrice.TotalFare.Taxes
    this.airPrice = this.props.datosAirPrice
    this.airBook = this.props.datosAirBook
    this.state = {
      collapseTasasyRecargos: true,
      modalSegundoPaso: false,
      modalTercerPaso: false,
      modalCuartoPaso: false,
      modal: false,
      //state emitir boleto
      emitirBoleto: [],
      //state formularioPagos Debito
      LocalizadorGTesoro: '',
      tipoPasajero: '',
      cedulaPFDebito: '',
      bancoPFDebito: '',
      codigoVerificacion: '',
      cuentasBancarias: [],
      nroCuentaTercerPaso: '',
      datosDebitoPrimerPaso: [],
      datosDebitoSegundoPaso: [],
      datosDebitoTercerPaso: [],
      datosDebitoCuartoPaso: [],
      codigoDebito: '',
      dataToResumen: [],
      //props peliculas
      
      //state formularioPagos Debito ACTIVO
      LocalizadorGActivo: '',
      tipoPasajeroActivo: '',
      cedulaPFDebitoActivo: '',
      bancoPFDebitoActivo: '',
      codigoVerificacionActivo: '',
      cuentasBancariasActivo: [],
      nroCuentaTercerPasoActivo: '',
      datosDebitoPrimerPasoActivo: [],
      datosDebitoSegundoPasoActivo: [],
      datosDebitoTercerPasoActivo: [],
      datosDebitoCuartoPasoActivo: [],
      codigoDebitoActivo: '',
      dataToResumenActivo: [],


      //state formularioPagos Debito BOD
      uidBod:'',
      tipoPasajeroBod: '',
      cedulaPFDebitoBod: '',
      bancoPFDebitoBod: '',
      codigoVerificacionBod: '',
      cuentasBancariasBod: [],
      nroCuentaTercerPasoBod: '',
      datosDebitoPrimerPasoBod: [],
      datosDebitoSegundoPasoBod: [],
      datosDebitoTercerPasoBod: [],
      datosDebitoCuartoPasoBod: [],
      codigoDebitoBod: '',
      // montoTotalForBod: this.props.montoTotal.toString() + '.00',
      cuentasMuestraBod:[],
    
      //state formulario Credito
      nombreTTarjetaC: "",
      emailTTarjetaC: "",
      cedulaTTarjetaC: "",
      numeroTTarjetaC: "",
      codigoSTTarjetaC: "",
      mesVencimientoTarjetaC: "",
      anioVencimientoTarjetaC: "",
      paisEmisorTarjetaC: "",
      codigoTelefonicoTarjetaC: "",
      codigoAreaTarjetaC: "",
      codigoNroTarjetaC: "",

      //state toggle pagoMovil
      bancoPM: "",
      codCedulaPM: "",
      cedulaTitularPM: "",
      diaPM: "",
      mesPM: "",
      añoPM: "",
      nroTelefonoPM: "",
      emailPM: "",
      codReferenciaPM: "",
      montoPM: "",
      vuelo: [],
      codigoPagoMovil: "",
      codAreaTlfPM: "",
      resultadoPM:'',

      //redireccion a resumen
      verResumen: false,

      //state formulario pago Petro
      correoPetro: "",
      confirmarCPetro: "",

      // state taxes
      taxes: [],
      totalFare: '',

      //metodo pago seleccionado
      metodoPago: '',

      //codigos de error
      codError: '',
      mensajeError: '',
      modalErrores: false,

      //redireccion
      redireccionResumen: false,

      // spinner pago movil  
      spinner: false, 
      spinnerContinuar: false,

      //modal transferencia
      modalTRF: false,
      modalReserva: false,

      emite: false,

      //codigo reserva
      codReserva: '',

      //conexus
      cedulaConexus: '',
      bancoConexus: '',
      telefonoConexus: '',
      otpConexus: '',

      //pago movil new
      bancoPMNew: '',
      telefonoPMNew: '',
    }
    
    // this.pCompletado = this.pCompletado.bind(this)
    
    //bind formularioPagos Debito
    this.handleChangeTipoPasajero = this.handleChangeTipoPasajero.bind(this);
    this.handleChangeCedulaPFDebito = this.handleChangeCedulaPFDebito.bind(this);
    this.handleChangeBancoPFDebito = this.handleChangeBancoPFDebito.bind(this);
    this.handleChangeCodigoVerificacion = this.handleChangeCodigoVerificacion.bind(this);
    this.handleChangeTercerPCuenta = this.handleChangeTercerPCuenta.bind(this);
    this.handleChangeTercerPCuentaActivo = this.handleChangeTercerPCuentaActivo.bind(this);
    this.handleChangeTercerPCuentaBod = this.handleChangeTercerPCuentaBod.bind(this);

    
    //activo
    this.handleChangeCodigoVerificacionActivo = this.handleChangeCodigoVerificacionActivo.bind(this);
    
    //bod
    this.handleChangeCodigoVerificacionBod = this.handleChangeCodigoVerificacionBod.bind(this);

    //bind formularioPagos Credito
    this.handleChangeNombreTTarjetaC = this.handleChangeNombreTTarjetaC.bind(this);
    this.handleChangeEmailTTarjetaC = this.handleChangeEmailTTarjetaC.bind(this);
    this.handleChangeCedulaTTarjetaC = this.handleChangeCedulaTTarjetaC.bind(this);
    this.handleChangeNroTTarjetaC = this.handleChangeNroTTarjetaC.bind(this);
    this.handleChangeCodigoSTTarjetaC = this.handleChangeCodigoSTTarjetaC.bind(this);
    this.handleChangeMesVTarjetaC = this.handleChangeMesVTarjetaC.bind(this);
    this.handleChangeAnioVTarjetaC = this.handleChangeAnioVTarjetaC.bind(this);
    this.handleChangePaisETarjetaC = this.handleChangePaisETarjetaC.bind(this);
    this.handleChangeCodTTarjetaC = this.handleChangeCodTTarjetaC.bind(this);
    this.handleChangeCodATarjetaC = this.handleChangeCodATarjetaC.bind(this);
    this.handleChangeCodNroTarjetaC = this.handleChangeCodNroTarjetaC.bind(this);
  
    //bind formularioPagos Pago Movil
    this.handleChangeBancoPM = this.handleChangeBancoPM.bind(this);
    this.handleChangeCodCedulaPM = this.handleChangeCodCedulaPM.bind(this);
    this.handleChangeCedulaPM = this.handleChangeCedulaPM.bind(this);
    this.handleChangeNroTelefonoPM = this.handleChangeNroTelefonoPM.bind(this);
    this.handleChangeDiaPM = this.handleChangeDiaPM.bind(this);
    this.handleChangeMesPM = this.handleChangeMesPM.bind(this);
    this.handleChangeAñoPM = this.handleChangeAñoPM.bind(this);
    this.handleChangeEmailPM = this.handleChangeEmailPM.bind(this);
    this.handleChangeCodReferenciaPM = this.handleChangeCodReferenciaPM.bind(this);
    this.handleChangeMontoPM = this.handleChangeMontoPM.bind(this);
    this.ejecutarPagoMovil = this.ejecutarPagoMovil.bind(this);
    this.handleChangeCodAreaTlfPM = this.handleChangeCodAreaTlfPM.bind(this);

    //bind formulario Petro
    this.handleChangeCorreoPetro = this.handleChangeCorreoPetro.bind(this);
    this.handleChangeConfirmarCPetro = this.handleChangeConfirmarCPetro.bind(this);

    this.handleChangeSubmit = this.handleChangeSubmit.bind(this);
    this.ejecutarSegundoPaso = this.ejecutarSegundoPaso.bind(this)

    // this.metodoPago = this.metodoPago.bind(this)

    //modal errores
    this.toggleModalError = this.toggleModalError.bind(this)
  }

  //RandomLocator: genera 15 digitos aleatorios
  randomLocator = (length) => {
    var result = '';
    var characters = '1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
  //Cerrar modal errores
  toggleModalError() {
    this.setState({modalErrores: false})
  }
  toggleModalResumen() {
    this.setState({modalResumen: false})
  }
  //toggle tasas y recargas
  toggleCollapseTasasyRecargos = collapseTasasyRecargos => () => {
    this.setState(prevState => ({
      collapseTasasyRecargos: prevState.collapseTasasyRecargos !== collapseTasasyRecargos ? collapseTasasyRecargos : ""
    }));
  }

   //toggle debito tesoro
   toggleModalSegundoPaso = () => {
    this.setState({
      modalSegundoPaso: !this.state.modalSegundoPaso
    });
  }
  toggleModalTercerPaso = () => {
    this.setState({
      modalTercerPaso: !this.state.modalTercerPaso
    });
  }
  toggleModalCuartoPaso = () => {
    this.setState({
      modalCuartoPaso: !this.state.modalCuartoPaso
    });
  }

  //toggle debito activo
  toggleModalSegundoPasoActivo = () => {
    this.setState({
      modalSegundoPasoActivo: !this.state.modalSegundoPasoActivo
    });
  }
  toggleModalTercerPasoActivo = () => {
    this.setState({
      modalTercerPasoActivo: !this.state.modalTercerPasoActivo
    });
  }
  toggleModalCuartoPasoActivo = () => {
    this.setState({
      modalCuartoPasoActivo: !this.state.modalCuartoPasoActivo
    });
  }

  //toggle debito bod
  toggleModalSegundoPasoBod = () => {
    this.setState({
      modalSegundoPasoBod: !this.state.modalSegundoPasoBod
    });
  }
  toggleModalTercerPasoBod = () => {
    this.setState({
      modalTercerPasoBod: !this.state.modalTercerPasoBod
    });
  }
  toggleModalCuartoPasoBod = () => {
    this.setState({
      modalCuartoPasoBod: !this.state.modalCuartoPasoBod
    });
  }

  //handleChange formulariopago Debito

  handleChangeTipoPasajero(e){
    this.setState({ tipoPasajero: e.target.value })
  }
  handleChangeCedulaPFDebito(e){
    this.setState({ cedulaPFDebito: e.target.value })
    this.props.cambiarCedula(e.target.value)
  }
  handleChangeBancoPFDebito(e){
    this.setState({ bancoPFDebito: e.target.value})
    this.props.cambiarBanco(e.target.value)
  }
  handleChangeCodigoVerificacion(e){
    this.setState({ codigoVerificacion: e.target.value})
  }
  handleChangeCodigoVerificacionActivo(e){
    this.setState({ codigoVerificacionActivo: e.target.value})
  }
  
  handleChangeCodigoVerificacionBod(e){
    this.setState({ codigoVerificacionBod: e.target.value})
  }
  
  handleChangeTercerPCuenta(e){
    this.setState({ nroCuentaTercerPaso: e.target.value })
  }

  handleChangeTercerPCuentaActivo(e){
    let cuentaActivo = e.target.value
    this.setState({
      nroCuentaTercerPasoActivo: cuentaActivo
    })
  }

  handleChangeTercerPCuentaBod(e){
    this.setState({ nroCuentaTercerPasoBod: e.target.value })
  }

//handleChange formulariopago Credito
  handleChangeNombreTTarjetaC(e){
    this.setState({ nombreTTarjetaC: e.target.value })
  }
  handleChangeEmailTTarjetaC(e){
    this.setState({ emailTTarjetaC: e.target.value })
  }
  handleChangeCedulaTTarjetaC(e){
    this.setState({ cedulaTTarjetaC: e.target.value })
  }
  handleChangeNroTTarjetaC(e){
    this.setState({ numeroTTarjetaC: e.target.value})
  }
  handleChangeCodigoSTTarjetaC(e){
    this.setState({ codigoSTTarjetaC: e.target.value })
  }
  handleChangeMesVTarjetaC(e){
    this.setState({ mesVencimientoTarjetaC: e.target.value})
  }
  handleChangeAnioVTarjetaC(e){
    this.setState({ anioVencimientoTarjetaC: e.target.value})
  }
  handleChangePaisETarjetaC(e){
    this.setState({ paisEmisorTarjetaC: e.target.value })
  }
  handleChangeCodTTarjetaC(e){
    this.setState({codigoTelefonicoTarjetaC: e.target.value })
  }
  handleChangeCodATarjetaC(e){
    this.setState({ codigoAreaTarjetaC: e.target.value })
  }
  handleChangeCodNroTarjetaC(e){
    this.setState({ codigoNroTarjetaC: e.target.value})
  }

  metodoPago = (prop) => {
    this.setState({
      metodoPago: prop,
      spinnerContinuar: false
    })
    // this.props.cambiarMetodo(prop)
    // console.log(prop);
  }
  //handleChange formulario Pago movil

  handleChangeBancoPM(e){
    this.setState({ bancoPM: e.target.value})
    // //console.log(e.target.value);
  }
  handleChangeCodCedulaPM(e){
    this.setState({ codCedulaPM: e.target.value})
  }
  handleChangeCedulaPM(e){
    this.setState({ cedulaTitularPM: e.target.value})
  }
  handleChangeNroTelefonoPM(e){
    this.setState({ nroTelefonoPM: e.target.value})
    // //console.log(e.target.value);
  }
  handleChangeDiaPM(e){
    this.setState({ diaPM: e.target.value})
    // console.log(this.state.diaPM)
  }
  handleChangeMesPM(e){
    this.setState({ mesPM: e.target.value})
    // console.log(this.state.mesPM)
  }
  handleChangeAñoPM(e){ 
    this.setState({ añoPM: e.target.value})
    // console.log(this.state.añoPM)
  }
  handleChangeEmailPM(e){
    this.setState({ emailPM: e.target.value})
  }
  handleChangeCodReferenciaPM(e){
    this.setState({ codReferenciaPM: e.target.value})
  }
  handleChangeCodAreaTlfPM(e){
    this.setState({ codAreaTlfPM: e.target.value })
    // console.log( e.target.value);
    
  }
  handleChangeMontoPM(e){
    let valueMask = e.target.value;

    while (valueMask.includes('.')) {
      valueMask = valueMask.replace(".", "")
    }
    valueMask = valueMask.replace(",", ".")
    // console.log(valueMask)
    this.setState({ montoPM: valueMask})
  }
  //handleChange formulario Petro

  handleChangeCorreoPetro(e){
    this.setState({ correoPetro: e.target.value })
  }
  handleChangeConfirmarCPetro(e){
    this.setState({ confirmarCPetro: e.target.value })
  }
  //redireccion a Resumen
  redireccion = () => {
    if (this.state.redireccionResumen) {
      this.props.cambiarPantalla("4")
    }
  }

  async handleChangeSubmit(e){
    e.preventDefault();

    await this.setState({
      spinnerContinuar: true
    })

    if (this.state.metodoPago === 'tdcp') {
      // if (this.state.emailTTarjetaC === this.state.emailTTarjetaCConfirmacion) {
        
      // } else {
      //   alert('Correo de confirmacion debe coincidir con el correo introducido')
      // }
      await this.payment()
      if (this.state.codigo === '00') {
        // await this.ejecutarEmitirBoleto()
      }
    } else if (this.state.metodoPago === 'tdd') {
      if (this.state.bancoPFDebito ==="tesoro") {
        console.log('tesoro');
        this.ejecutarPrimerPaso(e)
      }else if (this.state.bancoPFDebito ==="bod") {
        console.log('bod');
        this.ejecutarPrimerPasoBod(e)
      }else if (this.state.bancoPFDebito ==="activo") {
        console.log('activo');
        this.ejecutarPrimerPasoActivo(e)
      }else if (this.state.bancoPFDebito ==="amiga") {
        console.log('amiga');
      }
    } else if (this.state.metodoPago === 'pm') {
      this.ejecutarPagoMovil(e)
      this.spinner()
    } else if (this.state.metodoPago === 'pmc') {
      this.spinner()
      this.ejecutarPagoConexus(e)
    }else if (this.state.metodoPago === 'trf') {
      this.transferencias()
      // this.ticketLimitChange()
    } else if (this.state.metodoPago === 'reserva') {
      // await this.ticketLimitChange48()
      // console.log(this.state.codReserva)
      if (this.state.codReserva === '000') {
        await this.reservas()
      }
    } else {
      console.log('metodo no valido')
    }
  }

  async transferencias() {
    const url = 'https://payment.surnet.io:8088/API-mail'

    let caracteres = "1234567890";
    let codigo = "";
    let i
    for (i=0; i<12; i++) codigo +=caracteres.charAt(Math.floor(Math.random()*caracteres.length)); 
    // console.log(codigo)

    let datosTrf 
    
    if (this.props.isOneWay) {
      datosTrf = {
        bookingReference : {
          // locator: this.props.datosAirBook.BookingReference.Id,
          airline: "V0",
          oneWay: this.props.isOneWay,  
          codePay: codigo,
          amount: this.props.montoMuestra,
          amountFloat: parseFloat(this.props.montoTotal),
          paytype: "transfer",
          // name: `${this.props.datosAirBook.TravelInfos[0].GivenName} ${this.props.datosAirBook.TravelInfos[0].Surname}`,
          // mail: this.props.datosAirBook.TravelInfos[0].Email,
        },
        departure: {
          arrivalDateTime: this.props.datosAirBook.Segments[0].ArrivalDateTime,
          codeArrival: this.props.datosAirBook.Segments[0].CodeArrival,
          codeDeparture: this.props.datosAirBook.Segments[0].CodeDeparture,
          departureDateTime: this.props.datosAirBook.Segments[0].DepartureDateTime,
          flightNumber: this.props.datosAirBook.Segments[0].FlightNumber,
          resBookCode: this.props.datosAirBook.Segments[0].ResBookCode
        },
        arrival: {
          arrivalDateTime: "",
          codeArrival: "",
          codeDeparture: "",
          departureDateTime: "",
          flightNumber: "",
          resBookCode: ""
        },
      }
    } else {
      datosTrf = {
        bookingReference : {
          // locator: this.props.datosAirBook.BookingReference.Id,
          airline: "V0",
          oneWay: this.props.isOneWay,  
          codePay: codigo,
          amount: this.props.montoMuestra,
          amountFloat: parseFloat(this.props.montoTotal),
          paytype: "transfer",
          // name: `${this.props.datosAirBook.TravelInfos[0].GivenName} ${this.props.datosAirBook.TravelInfos[0].Surname}`,
          // mail: this.props.datosAirBook.TravelInfos[0].Email,
        },
        departure: {
          arrivalDateTime: this.props.datosAirBook.Segments[0].ArrivalDateTime,
          codeArrival: this.props.datosAirBook.Segments[0].CodeArrival,
          codeDeparture: this.props.datosAirBook.Segments[0].CodeDeparture,
          departureDateTime: this.props.datosAirBook.Segments[0].DepartureDateTime,
          flightNumber: this.props.datosAirBook.Segments[0].FlightNumber,
          resBookCode: this.props.datosAirBook.Segments[0].ResBookCode
        },
        arrival: {
          arrivalDateTime: this.props.datosAirBook.Segments[1].ArrivalDateTime,
          codeArrival: this.props.datosAirBook.Segments[1].CodeArrival,
          codeDeparture: this.props.datosAirBook.Segments[1].CodeDeparture,
          departureDateTime: this.props.datosAirBook.Segments[1].DepartureDateTime,
          flightNumber: this.props.datosAirBook.Segments[1].FlightNumber,
          resBookCode: this.props.datosAirBook.Segments[1].ResBookCode
        },
      }
    }
    await axios({
      method: 'post',
      url: url,
      data: datosTrf,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      this.setState({ 
        vuelo: data,
        spinnerContinuar: !this.state.spinnerContinuar,
        modalTRF: true
      })
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.ErrorCode, 
          mensajeError: error.response.data.ErrorMessage,
          spinnerContinuar: !this.state.spinnerContinuar
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
          spinnerContinuar: !this.state.spinnerContinuar
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
          spinnerContinuar: !this.state.spinnerContinuar
        })
      }
    })

    if (this.state.salir) {
      this.salir()
    }
  }

  setCedulaConexus = (e) => {
    this.setState({
      cedulaConexus: e.target.value
    })
    //console.log(e.target.value);
  }
  setTelefonoConexus = (e) => {
    this.setState({
      telefonoConexus: e.target.value
    })
    //console.log(e.target.value);
  }
  setBancoConexus = (e) => {
    this.setState({
      bancoConexus: e.target.value
    })
    //console.log(e.target.value);
  }
  setOtpConexus = (e) => {
    this.setState({
      otpConexus: e.target.value
    })
    //console.log(e.target.value);
  }
  setBancoPm = (e) => {
    this.setState({
      bancoPMNew: e.target.value
    })
    //console.log(e.target.value);
  }
  setTelefonoPm = (e) => {
    this.setState({
      telefonoPMNew: e.target.value
    })
    //console.log(e.target.value);
  }
  
  // conexus
  ejecutarPagoConexus = (e) => {
    e.preventDefault();
    this.pagoMovilC2P()
  }

  // tesoro
  ejecutarPrimerPaso = (e) => {
    e.preventDefault();
    this.debitoPrimerPaso()
    
  }
  ejecutarSegundoPaso = (e) => {
    e.preventDefault(); 
    this.debitoSegundoPaso()
  }
  ejecutarTercerPaso = (e) => {
    e.preventDefault();
    this.debitoTercerPaso()
    // if (this.state.codigoDebito === '00') {
    //   await this.ejecutarEmitirBoleto()      
    // }
    
  }
  ejecutarCuartoPaso = async() => {
    await this.debitoCuartoPaso()
    // if (this.state.codigoDebito === '00') {
    //   // await this.ejecutarEmitirBoleto()      
    // }
  }

  // activo
  ejecutarPrimerPasoActivo = (e) => {
    e.preventDefault();
    this.debitoPrimerPasoActivo()
    
  }
  ejecutarSegundoPasoActivo = (e) => {
    e.preventDefault(); 
    this.debitoSegundoPasoActivo()
  }
  ejecutarTercerPasoActivo = (e) => {
    e.preventDefault();
    this.debitoTercerPasoActivo()
    // if (this.state.codigoDebito === '00') {
    //   await this.ejecutarEmitirBoleto()      
    // }
    
  }
  ejecutarCuartoPasoActivo = async() => {
    await this.debitoCuartoPasoActivo()
    if (this.state.codigoDebito === '000') {
      // await this.ejecutarEmitirBoleto()      
    }
  }

  // bod
  ejecutarPrimerPasoBod = (e) => {
    e.preventDefault();
    this.debitoPrimerPasoBod()
  }
  ejecutarSegundoPasoBod = (e) => {
    e.preventDefault(); 
    this.setState({
      spinnerContinuar: true
    })
    this.debitoSegundoPasoBod()
  }
  ejecutarTercerPasoBod = (e) => {
    e.preventDefault();
    this.debitoTercerPasoBod()
  }
  ejecutarCuartoPasoBod = async() => {
    let needDB = true
    if (needDB) {
      await this.debitoCuartoPasoBod()
    }
    if (this.state.codigoDebito === '00') {
      await this.ejecutarEmitirBoleto()
      needDB = false        
    }
  }

  ejecutarEmitirBoleto = () => {
    this.emitirBoleto()
  }

  ejecutarPagoMovil = async () => {
    await this.pagoMovil()
  }

  //obtener fecha
  getDate = () => {
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(day<10)day='0'+day; //agrega cero si el menor de 10
    if(month<10)month='0'+month; //agrega cero si el menor de 10

    return year + month  + day;//format: yyyy-mm-dd;
  }
  getDate2 = () => {
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(day<10)day='0'+day; //agrega cero si el menor de 10
    if(month<10)month='0'+month; //agrega cero si el menor de 10

    return day + month  + year;//format: yyyy-mm-dd;
  }
  

  checkCreditCards(e){
    // console.log(e);
    var cc = e.substr(0,6);
    var badcard = banned_tdc;
    
    if(badcard.indexOf(cc) !== -1){
        //esto es lo que devuelve si el dato existe en el arreglo
        return true 
    } else{
        //esto es lo que devuelve si el dato no existe en el arreglo
        return false
    }
  }

  async payment() {
    // console.log('ejecutando payment');
    // const url = 'https://payment.surnet.io:8084/'
    // const url = 'https://payment.surnet.io/TBACR'
    // const url = 'https://api.surnet.io/baclickaplay'
    const url = 'https://test.surnet.io/bancamigaclickaplay'
    let codigoCredito
    const visa = new RegExp("4[0-9]{15}$")
    const visaTreceDigitos = new RegExp("4[0-9]{12}$")
    const masterCard = new RegExp("5[1-5][0-9]{14}$")
    const valid_credit_card = await this.checkCreditCards(this.state.numeroTTarjetaC)

    if (visa.test(this.state.numeroTTarjetaC)) {
      codigoCredito = '1'
      // console.log(codigoCredito)
    } else if (masterCard.test(this.state.numeroTTarjetaC)) {
      codigoCredito = '2'
      // console.log(codigoCredito)
    } else if (visaTreceDigitos.test(this.state.numeroTTarjetaC)) {
      codigoCredito = '1'
      // console.log(codigoCredito)
    }
    let currency_selected = ""
    if (this.props.coin_metodo_pago === "BS") {
      currency_selected = "VES";
    } else if (this.props.coin_metodo_pago === "USD") {
      currency_selected = "USD";
    }

    let localizador_real = this.randomLocator(15);

    let datosTarjeta = {         
      // Apitoken: "9C5FC7A272AC5D2E4569C78A56CE6D7DA92B7161613A01FC4D73EECB6C39C647", //test
      // Apitoken: "D7F07E0C21096DA495E7D8CCCBD1F391A32AE675C55BBEC785C725647EBFD503", //prod
      monto: this.props.precio_pelicula_usd,
      card_number: this.state.numeroTTarjetaC,
      mes: this.state.mesVencimientoTarjetaC,
      year: this.state.anioVencimientoTarjetaC,
      cvc: this.state.codigoSTTarjetaC,
      moneda: currency_selected,
      tipo_tarjeta: codigoCredito,
      direccion_postal: "VE",
      referencia: localizador_real,
      airline: 'N/A',
      locator: localizador_real,
      montoFloat: parseFloat(this.props.precio_pelicula_usd)
    }
    if (valid_credit_card === true) {
      this.setState({
        modalErrores: true, 
        codError: "160", 
        mensajeError: "El número de tarjeta no esta permitido ",
        spinnerContinuar: !this.state.spinnerContinuar
      })
    } else {
      await axios({
        method: 'post',
        url: url,
        data: datosTarjeta,
        headers: {'Content-Type':'application/json'},
      })
      .then(response => response.data)
      .then((data) => { 
        this.setState({ 
          datoCredito: data,
          codigo: data.respuesta_codigo,
          spinnerContinuar: !this.state.spinnerContinuar
        })
        if (data.respuesta_codigo === '00') {
          // console.log('success');
          this.props.allData2(data)
          this.consultarUrlPelicula()
        } else {
          this.setState({
            modalErrores: true,
            codError: data.codigo,
            mensajeError: data.mensaje,
            spinnerContinuar: !this.state.spinnerContinuar
          })
        }
        // console.log(this.state.datoCredito)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.ErrorMessage);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.setState({
            modalErrores: true, 
            codError: error.response.data.codigo, 
            mensajeError: error.response.data.mensaje,
            spinnerContinuar: !this.state.spinnerContinuar
          })
        } else if (error.request) {
          /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
          console.log(error.request);
          this.setState({
            modalErrores: true, 
            codError: 'Error', 
            mensajeError: 'ha ocurrido un error al conectar con bancamiga',
            spinnerContinuar: !this.state.spinnerContinuar
          })
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({
            modalErrores: true,
            codError: 'Error',
            mensajeError: 'ha ocurrido un error al conectar con bancamiga',
            spinnerContinuar: !this.state.spinnerContinuar
          })
        }
      })
    }

  }

  // async updatePagoCompletado() {
  //   const url = `https://test.surnet.io/PagoCompletado`
    
  //   let datosPeliculas = {
  //     locator: this.props.locator_global,
  //     email: this.props.email_global,
  //     idmovie: this.props.url_pelicula,
  //     ip: this.props.ip_global
  //   }

  //   await axios({
  //     method: 'post',
  //     url: url,
  //     data: datosPeliculas,
  //     headers: {'Content-Type':'application/json'},
  //   })
  //   .then(response => response.data)
  //   .then((data) => { 
  //     // console.log(data, "proceso");
  //     if (data.codre === '00') {
  //       console.log('aprobado');
  //     } else {
  //       this.setState({
  //         modalErrores: true, 
  //         codError: data.codres, 
  //         mensajeError: data.mensaje,
  //         spinnerContinuar: false
  //       })
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error.response);
  //     if (error.response) {
  //       this.setState({ 
  //         modalErrores: true, 
  //         codError: error.response.data.Codres,
  //         mensajeError: error.response.data.mensaje,
  //         spinnerContinuar: !this.state.spinnerContinuar,
  //         spinner: !this.state.spinner
  //       })
  //     } else if (error.request) {
  //       console.log(error.request);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: error.response.data.Codres,
  //         mensajeError: error.response.data.mensaje,
  //         spinnerContinuar: !this.state.spinnerContinuar,
  //         spinner: !this.state.spinner
  //       })
  //     } else {
  //       // Something happened in setting up the request and triggered an Error
  //       console.log('Error', error.message);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: error.response.data.Codres,
  //         mensajeError: error.response.data.mensaje,
  //         spinnerContinuar: !this.state.spinnerContinuar,
  //         spinner: !this.state.spinner
  //       })
  //     }
  //   })

  // }
 //conexus c2p
  pagoMovilC2P() {
    // console.log('Ejecutando conexus pago movil');
    let telefonoSinCero = this.state.telefonoConexus.substr(1,10);
    const url = `https://test.surnet.io/mic2p`
    let datosConexus

    // console.log(this.props.montoTotal, "c2p");

    datosConexus = {
      apitoken: "00a369c2a3f93aab24fd35c37b2d0d13c15803dc1f099e72aa982fad3319f5a0",
      accountID_2: `1258${telefonoSinCero}`,
      client_ID: `009DI${this.state.cedulaConexus}`,
      issuing_bank: this.state.bancoConexus,                                         
      otp: this.state.otpConexus,
      qr_hash: 'N/A',
      coin: "928",
      date: this.getDate2().toString(), 
      type: "send",
      // amount: this.props.montoTotal.toString()
      amount: this.props.precio_pelicula_bs
    }

    axios({
      method: 'post',
      url: url,
      data: datosConexus,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data, "proceso");
      if (data.codres === '00') {
        this.setState({ 
          codReferenciaPM: data.reference,
          spinnerContinuarActivo: false,
        })
        this.props.allData(data)
        this.consultarUrlPelicula()
        this.props.pCompletado(this.state)
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        this.setState({ 
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      }
    })

  }
  //movie
  consultarUrlPelicula() {
    // console.log('Ejecutando conexus pago movil');
    // let telefonoSinCero = this.state.telefonoConexus.substr(1,10);
    // console.log(this.props.url_pelicula);
    const url = `https://prod.flexve.com/TokenMovie`
    let datospeli

    // console.log(this.props.montoTotal, "c2p");

    datospeli = {
      videoId: this.props.url_pelicula
    }

    axios({
      method: 'post',
      url: url,
      data: datospeli,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data, "proceso");
      if (data.code === '000') {
        this.props.allDatapeli(data)
        this.props.closeModal2(false)
        this.props.openResumen(true)
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        this.setState({ 
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.Codres,
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: !this.state.spinnerContinuar,
          spinner: !this.state.spinner
        })
      }
    })

  }

 //debito activo
  debitoPrimerPasoActivo() {
    // console.log('Ejecutando debito activo');
    const url = `https://test.surnet.io/debito`
    let datosPrimerPaso

    datosPrimerPaso = {
      APITOKEN:"63ac6d906fe2635092a0b20b9ac7c085608856d9adcf8b44069c4be0c2a10855",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id, 
      CIRIF: this.state.cedulaPFDebito,
      TIPO: this.state.tipoPasajero,                                           
      BANCO: "0171", 
      FECHA: this.getDate(), 
      MONEDA: "VES", 
      MONTO: parseFloat(this.props.montoTotal),
      METODO: "PagoDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosPrimerPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data);
      if (data.codres === '01') {
        this.setState({ 
          datosDebitoPrimerPasoActivo: data, 
          modalSegundoPasoActivo: true,
          cedulaPFDebitoActivo: datosPrimerPaso.Foid,
          spinnerContinuarActivo: false
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      }
    })

  }
  debitoSegundoPasoActivo() { 
    const url = `https://test.surnet.io/debito`
    let datosSegundoPaso

    datosSegundoPaso = {
      APITOKEN:"63ac6d906fe2635092a0b20b9ac7c085608856d9adcf8b44069c4be0c2a10855",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id,
      TOKEN: this.state.codigoVerificacionActivo, 
      METODO: "TokenDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosSegundoPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data);
      if(data.codres === '02') {
        this.setState({ 
          datosDebitoSegundoPasoActivo: data,
          cuentasBancariasActivo: data.cuentas,
          modalSegundoPasoActivo: false,
          modalTercerPasoActivo: true
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error) {
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false,
        })
      }
    })
  }
  debitoTercerPasoActivo() {
    const url = `https://test.surnet.io/debito`
    let datosTercerPaso

    datosTercerPaso = {
      APITOKEN:"63ac6d906fe2635092a0b20b9ac7c085608856d9adcf8b44069c4be0c2a10855",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id, 
      CUENTA: this.state.nroCuentaTercerPasoActivo,
      TOKEN: this.state.codigoVerificacionActivo, 
      METODO: "DebitarDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosTercerPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      // console.log(data);
      if (data.codres === '00') {
        this.setState({
          dataToResumenActivo: data,
          codigoDebito: data.codres,
        })
        // this.sendData()
        this.ejecutarEmitirBoleto()
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error) {
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false,
        })
      }
    })
  }

  //debito tesoro
  debitoPrimerPaso() {
    // console.log('Ejecutando debito tesoro');
    const url = `https://test.surnet.io/debito`
    let datosPrimerPaso

    datosPrimerPaso = {
      APITOKEN:"uytdg3ey5r8t8ye538w4t8f4l4fwa96uj5tys=a4=erq27s98746443e7=843tsq",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id, 
      CIRIF: this.state.cedulaPFDebito,
      TIPO: this.state.tipoPasajero,                                           
      BANCO: "0163", 
      FECHA: this.getDate(), 
      MONEDA: "VES", 
      MONTO: parseFloat(this.props.montoTotal),
      METODO: "PagoDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosPrimerPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data);
      if (data.codres === '01') {
        this.setState({ 
          datosDebitoPrimerPaso: data, 
          modalSegundoPaso: true,
          // cedulaPFDebito: datosPrimerPaso.Foid,
          spinnerContinuar: false
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false
        })
      }
    })

  }
  debitoSegundoPaso() {
    const url = `https://test.surnet.io/debito`
    let datosSegundoPaso

    datosSegundoPaso = {
      APITOKEN:"uytdg3ey5r8t8ye538w4t8f4l4fwa96uj5tys=a4=erq27s98746443e7=843tsq",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id,
      TOKEN: this.state.codigoVerificacion, 
      METODO: "TokenDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosSegundoPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data);
      if(data.codres === '02') {
        this.setState({ 
          datosDebitoSegundoPaso: data,
          cuentasBancarias: data.cuentas,
          modalSegundoPaso: false,
          modalTercerPaso: true,
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error) {
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false,
        })
      }
    })
  }
  debitoTercerPaso() {
    const url = `https://test.surnet.io/debito`
    let datosTercerPaso
    
    datosTercerPaso = {
      APITOKEN:"uytdg3ey5r8t8ye538w4t8f4l4fwa96uj5tys=a4=erq27s98746443e7=843tsq",  
      // LOCATOR: this.props.datosAirBook.BookingReference.Id, 
      CUENTA: this.state.nroCuentaTercerPaso,
      TOKEN: this.state.codigoVerificacion, 
      METODO: "DebitarDC"
    }

    axios({
      method: 'post',
      url: url,
      data: datosTercerPaso,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      // console.log(data);
      if (data.codres === '00') {
        this.setState({
          dataToResumen: data,
          codigoDebito: data.codres,
        })
        // this.sendData()
        this.ejecutarEmitirBoleto()
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.codres, 
          mensajeError: data.mensaje,
        })
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error) {
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else if (error.request) {
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.codres, 
          mensajeError: error.response.data.mensaje,
          spinnerContinuar: false,
        })
      } else {
        console.log('Error', error.message);
        this.setState({
          codError: error.code, 
          mensajeError: error.message,
          spinnerContinuar: false,
        })
      }
    })
  }

  //pasos debito bod
  debitoPrimerPasoBod(){
    const url = `https://test.surnet.io/WSClientes`
    let cedulaCliente = this.state.cedulaPFDebito
    let prefijoCedula = this.state.tipoPasajero
    let tipoDocumento = ''
    let cuid = require('cuid');
    let uid = cuid()
    // console.log(uid);

    this.setState({
      uidBod: uid
    })
    
    if (prefijoCedula === 'V' || prefijoCedula === 'E' ) {
      tipoDocumento = 'CI'
    }else{
      tipoDocumento = 'RIF'
    }

    let datosPrimerPasoBod
    datosPrimerPasoBod ={
      rq_uid: this.state.uidBod,
      bank_id: "01",
      channe_code: "INT",
      issued_loc: "001",
      event_code: "BTN",
      event_num: "0805",
      shipping_method: "2",
      reference_number: "NBL0001",
      issued_ident_type: prefijoCedula,
      issued_ident_value: cedulaCliente,
      issued_doc_type: tipoDocumento
    }
    axios({
      method: 'post',
      url: url,
      data: datosPrimerPasoBod,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      // console.log(data);
      
      if (data.code === '00') {
        this.setState({ 
          datosDebitoPrimerPasoBod: data, 
          modalSegundoPasoBod: true,
          // cedulaPFDebitoBod: datosPrimerPasoBod.Foid,
          spinnerContinuar: false
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.code, 
          mensajeError: data.message,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.ErrorCode, 
          mensajeError: error.response.data.ErrorMessage,
          spinnerContinuar: false
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
          spinnerContinuar: false
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
          spinnerContinuar: false
        })
      }
    })
  }
  debitoSegundoPasoBod() {
    const url = `https://test.surnet.io/WSOtpValidate`
    let datosSegundoPasoBod
    let paryaid = this.state.datosDebitoPrimerPasoBod.party_id;
    let payerId = paryaid.toString()
    let codTelefono = this.state.codigoVerificacionBod
    let codFinal = codTelefono.toString()

    datosSegundoPasoBod = {
      rq_uid: this.state.uidBod,
      bank_id: "01",
      channe_code: "INT",
      party_id: payerId,
      otp: codFinal,
      // amount: this.state.montoTotalForBod,
      amount:'10.30'
    }

    console.log(this.state.montoTotalForBod)
    console.log('====================================');
    console.log(codFinal);
    console.log('====================================');
    axios({
      method: 'post',
      url: url,
      data: datosSegundoPasoBod,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      if(data.code === '00' || data.party_id !== '0000' || data.code !== '03') {
        let cuentas = []
        data.account.map((item,index)=>{
          cuentas[index] = item.substring(4,8)
          cuentas[index] = '****'+ cuentas[index]
          return true
        })
        this.setState({ 
          datosDebitoSegundoPasoBod: data,
          cuentasBancariasBod: data.account,
          modalSegundoPasoBod: false,
          modalTercerPasoBod: true,
          cuentasMuestraBod: cuentas,
          spinnerContinuar: false
        })
        console.log('====================================');
        console.log(this.state.datosDebitoSegundoPasoBod);
        console.log('====================================');
      } else {
        this.setState({
          modalErrores: true,
          codError: data.code,
          mensajeError: data.message,
          spinnerContinuar: false
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.ErrorCode, 
          mensajeError: error.response.data.ErrorMessage,
          spinnerContinuar: false
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Introduzca el codigo correctamente e intente de nuevo',
          spinnerContinuar: false
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Introduzca el codigo correctamente e intente de nuevo',
          spinnerContinuar: false
        })
      }
    })
  }
  debitoTercerPasoBod() {
    const url = `https://test.surnet.io/WithholdingPayment`
    let datosTercerPasoBod

    datosTercerPasoBod = {
      rq_uid: this.state.uidBod,
      bank_id: "01",
      channe_code: "INT",
      acct_id: this.state.nroCuentaTercerPasoBod,
      party_id: this.state.datosDebitoSegundoPasoBod.party_id,
      // amount: this.state.montoTotalForBod,
      amount:'10.30',
      cliente_ext: "200077743",
      // locator: this.props.datosAirBook.BookingReference.Id,
      company_code: "V0",
      issued_ident_value: this.state.cedulaPFDebito,
      issued_doc_type: this.state.tipoPasajero,
      target: 'Testing',
      // email: this.props.datosAirBook.TravelInfos[0].Email
    }

    axios({
      method: 'post',
      url: url,
      data: datosTercerPasoBod,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      console.log(data);
      
      if (data.code === '00') {
        this.setState({ 
          datosDebitoTercerPasoBod: data,
          modalTercerPasoBod: false,
          modalCuartoPasoBod: true
        })
      } else {
        this.setState({
          modalErrores: true, 
          codError: data.code, 
          mensajeError: data.message
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({
          modalErrores: true, 
          codError: error.response.data.ErrorCode, 
          mensajeError: error.response.data.ErrorMessage
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
        })
      }
    })
  }
  async debitoCuartoPasoBod() {
    const url = `https://test.surnet.io/AccountDebit`
    let datosCuartoPasoBod
    
    console.log('====================================');
    console.log(this.state.datosDebitoTercerPasoBod.ref);
    console.log('====================================');
    this.props.datosTransferenciaBod(this.state.datosDebitoTercerPasoBod.ref)

    datosCuartoPasoBod = {
      rq_uid: this.state.uidBod,
      bank_id: "01",
      channe_code: "INT",
      acct_id: this.state.nroCuentaTercerPasoBod,
      party_id: this.state.datosDebitoSegundoPasoBod.party_id,
      // amount: this.state.montoTotalForBod,
      amount:'10.30',
      cliente_ext: "200077743",
      ref: this.state.datosDebitoTercerPasoBod.ref,
      // locator: this.props.datosAirBook.BookingReference.Id,
      company_code: "V0",
      issued_ident_value: this.state.cedulaPFDebito,
    }

    await axios({
      method: 'post',
      url: url,
      data: datosCuartoPasoBod,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => { 
      if (data.code === '00') {
        console.log(data);
        this.setState({ 
          datosDebitoCuartoPaso: data,
          modalTercerPaso: false, 
          modalCuartoPaso: false,
          codigoDebito: data.code,
        })
      } else {
        this.setState({
          modalTercerPaso: false,
          modalCuartoPaso: false,
          modalErrores: true, 
          codError: data.code, 
          mensajeError: data.message
        })
      }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({
          modalCuartoPaso: false, 
          modalTercerPaso: false, 
          modalErrores: true, 
          codError: error.response.data.ErrorCode, 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
        })
      }
    })
  }

  // emitirBoleto() {
  //   const url = `${ruta.urlKiu}/AirDemandTicket`
  //   let datosEmitirBoleto 
  //   let token
  //   let referencia 
  //   let codigoCompra

  //   if (this.props.scope === 'I') {
  //     if (this.props.currency === 'USD') {
  //       token = tokenKiu.tokenI
  //     } else {
  //       token = tokenKiu.tokenN
  //     }
  //     this.setState({isI11l: true})
  //   } else {
  //     token = tokenKiu.tokenN
  //     this.setState({isI11l: false})
  //   }
    
  //   if (this.state.metodoPago === 'tdd') {
  //     if (this.state.bancoPFDebito ==="tesoro") {
  //       referencia = this.state.dataToResumen.referencia
  //     }else if (this.state.bancoPFDebito ==="bod") {
  //       referencia = this.state.datosDebitoTercerPasoBod.ref
  //     }else if (this.state.bancoPFDebito ==="activo") {
  //       console.log('activo')
  //       referencia = this.state.dataToResumenActivo.referencia
  //     }else if (this.state.bancoPFDebito ==="amiga") {
  //       console.log('amiga')
  //     }
  //     codigoCompra = 'DB'
  //   } else if (this.state.metodoPago === 'pm') {
  //     referencia = this.state.codReferenciaPM
  //     codigoCompra = 'PM'
  //   } else if (this.state.metodoPago === 'pmc') {
  //     referencia = this.state.codReferenciaPM
  //     codigoCompra = 'PM'
  //   } else {
  //     console.log('metodo no aceptado')
  //   }

  //   datosEmitirBoleto = {
  //     localizator: this.props.datosAirBook.BookingReference.Id,
  //     payment_type: 37,
  //     misc: {
  //       // codigo compra temporal
  //       // miscellaneous_code: 'DB',
  //       miscellaneous_code: codigoCompra,
  //       text: referencia,
  //       vat: '0'
  //     },
  //     sequence_nmbr: this.props.sequenceNumber  
  //   }

  //   axios({
  //     method: 'post',
  //     url: url,
  //     data: datosEmitirBoleto,
  //     headers: {
  //         'Content-Type':'application/json', 
  //         'Authorization': token
  //     },
  //   })
  //   .then(response => response.data)
  //   .then((data) => { 
  //     this.setState({ 
  //       emitirBoleto: data,
  //       redireccionResumen: true
  //     })
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       console.log(error.response.data.ErrorMessage);
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: '10', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente pulsando el boton continuar'
  //       })
  //     } else if (error.request) {
  //       /*
  //         * The request was made but no response was received, `error.request`
  //         * is an instance of XMLHttpRequest in the browser and an instance
  //         * of http.ClientRequest in Node.js
  //         */
  //       console.log(error.request);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
  //       })
  //     } else {
  //       // Something happened in setting up the request and triggered an Error
  //       console.log('Error', error.message);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente'
  //       })
  //     }
  //   })
  
  // }

  getDate3 = () => {
    var day = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if(day<10)day='0'+day; //agrega cero si el menor de 10
    if(month<10)month='0'+month; //agrega cero si el menor de 10

    return year + "-"+ month + "-" + day;//format: yyyy-mm-dd;
  }

  async pagoMovil() {
    let telefonoSinCero = this.state.telefonoPMNew.substr(1,10);
    let datosPagoMovil
    // console.log(this.getDate3());

    var newDatePM = this.getDate3().replace(/-/g, '');

    const url = `https://prod.flexve.com/pagomovilA`
    datosPagoMovil = {
      LOCATOR: this.props.locator_global,
	    FIDEMPRESA: "CCSCON03",
	    IP: this.props.ip_global,
	    FMONTO: this.props.precio_pelicula_bs,
	    FBANCO: this.state.bancoPMNew,
	    CURRENCY: "VES",
	    FFECHATR: newDatePM,
	    PROFORMA: this.props.locator_global,
	    FTELEFONO: `58${telefonoSinCero}`
    }

      await axios({
        method: 'post',
        url: url,
        data: datosPagoMovil,
        headers: {'Content-Type':'application/json'},
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.codre === '00') {
          this.setState({ 
            loading: true,
            codReferenciaPM: response.data.refe,
          })
          this.props.allData(response.data)
          this.consultarUrlPelicula()
          this.props.pCompletado(this.state)
          if (response.data.ref === "000000000000") {
            this.setState({
              modalErrores: true, 
              codError: '09',
              mensajeError: 'Estimado cliente la referencia no pudo ser validada por favor intente nuevamente',
            })
          }
        } else {
          this.setState({
            modalErrores: true, 
            codError: response.data.code,
            mensajeError: response.data.message,
          })
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          this.setState({ 
            modalErrores: true, 
            codError: error.response.data.code,
            mensajeError: error.response.data.Message ? error.response.data.Message : error.response.data.message,
            spinnerContinuar: !this.state.spinnerContinuar,
            spinner: !this.state.spinner
          })
        } else if (error.request) {
          console.log(error.request);
          this.setState({
            modalErrores: true, 
            codError: error.response.data.code,
            mensajeError: error.response.data.Message ? error.response.data.Message : error.response.data.message,
            spinnerContinuar: !this.state.spinnerContinuar,
            spinner: !this.state.spinner
          })
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({
            modalErrores: true, 
            codError: error.response.data.code,
            mensajeError: error.response.data.Message ? error.response.data.Message : error.response.data.mesaage,
            spinnerContinuar: !this.state.spinnerContinuar,
            spinner: !this.state.spinner
          })
        }
      })
  }

  salir = () => {
    window.location.href = "https://clickaplay.com/"
  }

  componentDidMount() {
    // let fares = []
    // let TotalFare = 0

    // Object.entries(this.taxes).forEach(([code, amount]) => {
    //   fares.push({ code, amount });
    //   TotalFare = TotalFare + parseFloat(amount, 10)
    //   console.log(TotalFare)
    // });

    // this.setState({
    //   taxes: fares,
    //   totalFare: TotalFare.toFixed(2)
    // })
  }

  spinner(){
    this.setState({
      spinner: true
    })
    // console.log(this.state.spinner)
  }
    

  //Funcion para reservas

  reservas() {
    let datosPagoMovil
    const url = `https://test.surnet.io:8091/Email-Reserva`

    datosPagoMovil = {
      // locator: this.props.datosAirBook.BookingReference.Id,
      // name: `${this.props.datosAirBook.TravelInfos[0].GivenName} ${this.props.datosAirBook.TravelInfos[0].Surname}`,
      // mail: this.props.datosAirBook.TravelInfos[0].Email,
      amount: this.props.montoMuestra,
      currercy: this.props.currency
    }
    axios({
      method: 'post',
      url: url,
      data: datosPagoMovil,
      headers: {'Content-Type':'application/json'},
    })
    .then(response => response.data)
    .then((data) => {
      this.setState({
        modalReserva: true,
        spinner: false, 
        spinnerContinuar: false,
      })
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.ErrorMessage);
        console.log(error.response.status);
        console.log(error.response.headers);
        this.setState({ 
          modalErrores: true, 
          codError: error.response.data.codigo,
          mensajeError: error.response.data.mensaje,
          spinner: false, 
          spinnerContinuar: false,
        })
      } else if (error.request) {
        /*
          * The request was made but no response was received, `error.request`
          * is an instance of XMLHttpRequest in the browser and an instance
          * of http.ClientRequest in Node.js
          */
        console.log(error.request);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        this.setState({
          modalErrores: true, 
          codError: 'Error', 
          mensajeError: 'Ha ocurrido un error de conexión con el Banco',
          spinner: false, 
          spinnerContinuar: false,
        })
      }
    })
  }

  // async ticketLimitChange48() {
  //   const url = `https://test.surnet.io:8089/AirBookModify-48`
  //   let token

  //   let datosCambio = {
  //     locator: this.props.datosAirBook.BookingReference.Id
  //   }

  //   if (this.props.scope === 'I') {
  //     if (this.props.currency === 'USD') {
  //       token = tokenKiu.tokenI
  //     } else {
  //       token = tokenKiu.tokenN
  //     }
  //     this.setState({isI11l: true})
  //   } else {
  //     token = tokenKiu.tokenN
  //     this.setState({isI11l: false})
  //   }

  //   await axios({
  //     method: 'post',
  //     url: url,
  //     data: datosCambio,
  //     headers: {
  //       'Content-Type':'application/json',
  //       'Authorization': token
  //     },
  //   })
  //   .then(response => response.data)
  //   .then((data) => { 
  //     this.setState({ 
  //       codReserva: data.code
  //     })
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       console.log(error.response.data.ErrorMessage);
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: error.response.data.ErrorCode, 
  //         mensajeError: error.response.data.ErrorMessage,
  //         spinner: false, 
  //         spinnerContinuar: false,
  //       })
  //     } else if (error.request) {
  //       /*
  //         * The request was made but no response was received, `error.request`
  //         * is an instance of XMLHttpRequest in the browser and an instance
  //         * of http.ClientRequest in Node.js
  //         */
  //       console.log(error.request);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinner: false, 
  //         spinnerContinuar: false,
  //       })
  //     } else {
  //       // Something happened in setting up the request and triggered an Error
  //       console.log('Error', error.message);
  //       this.setState({
  //         modalErrores: true, 
  //         codError: 'Error', 
  //         mensajeError: 'ha ocurrido un error inesperado intente nuevamente',
  //         spinner: false, 
  //         spinnerContinuar: false,
  //       })
  //     }
  //   })
  // }

  //cerrar modal reserva temporal
  cerrarReserva = () => {
    this.setState({
      modalReserva: false
    })
  }

  cancelModal = () => {
    this.props.closeModal2(false)
  }

  render() {
    let spinnerContinuar
    if(this.state.spinnerContinuar){
        spinnerContinuar = <div>
                              <MDBBtn 
                                 className="boton-modal-1-aceptar" 
                                 style={{borderColor:"#3fa044", borderRadius:"0"}}
                                 block
                                 type="button"
                                 disabled
                              >
                                <div className="spinner-border spinner-border-sm white-text" role="status" color="white">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </MDBBtn>   
                            </div>
    } else{
        spinnerContinuar = <div>
                              <MDBBtn 
                                 className="boton-modal-1-aceptar" 
                                 style={{borderColor:"#3fa044", borderRadius:"0"}}
                                 block
                                 type="submit"
                              >
                                CONTINUAR
                              </MDBBtn>   
                            </div>
    };

    return (
      <div>
        {this.redireccion()}
        <MDBRow style={{justifyContent: 'center', marginTop:"-20px"}}>
          <MDBCol sm="12" md="12" lg="12">
            <form onSubmit={this.handleChangeSubmit}>
              <MDBRow className="mt-1" style={{padding:0, margin:0}}>
                <MDBCol  className="colorAccent">
                  <MDBRow className="colorConviasa caja">
                    <MDBCol>
                      <MDBRow style={{justifyContent:"center "}} >
                        <strong className="strong-subtitle">Seleccione su método de pago</strong>    
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <SelectorPagos
                    tipoPasajero={this.handleChangeTipoPasajero}
                    cedulaDebito={this.handleChangeCedulaPFDebito}
                    bancoDebito={this.handleChangeBancoPFDebito}
                    nombreTTarjetaC={this.handleChangeNombreTTarjetaC}
                    emailTTarjetaC={this.handleChangeEmailTTarjetaC}
                    cedulaTTarjetaC={this.handleChangeCedulaTTarjetaC}
                    nroTTarjetaC={this.handleChangeNroTTarjetaC}
                    codigoSTTarjetaC={this.handleChangeCodigoSTTarjetaC}
                    mesVTarjetaC={this.handleChangeMesVTarjetaC}
                    anioVTarjetaC={this.handleChangeAnioVTarjetaC}
                    paisETarjetaC={this.handleChangePaisETarjetaC}
                    codTTarjetaC={this.handleChangeCodTTarjetaC}
                    codATarjetaC={this.handleChangeCodATarjetaC}
                    codNroTarjetaC={this.handleChangeCodNroTarjetaC}
                    bancoPM={this.handleChangeBancoPM}
                    codCedulaPM={this.handleChangeCodCedulaPM}
                    cedulaPM={this.handleChangeCedulaPM}
                    nroTelefonoPM={this.handleChangeNroTelefonoPM}
                    diaPM={this.handleChangeDiaPM}
                    mesPM={this.handleChangeMesPM}
                    añoPM={this.handleChangeAñoPM}
                    emailPM={this.handleChangeEmailPM}
                    codReferenciaPM={this.handleChangeCodReferenciaPM}
                    codAreaTlfPM={this.handleChangeCodAreaTlfPM}
                    pagoMovil={this.ejecutarPagoMovil}
                    correoPetro={this.handleChangeCorreoPetro}
                    confirmarCorreoPetro={this.handleChangeConfirmarCPetro}
                    metodoPago={this.metodoPago}
                    codArea={this.state.codigoTelefonicoTarjetaC}
                    airBook={this.airBook}
                    airPrice={this.airPrice}
                    scope={this.props.scope}
                    currency={this.props.currency}
                    total={this.props.montoTotal}
                    montoMuestra={this.props.montoMuestra}
                    spinner={this.state.spinner}
                    precioPetro={this.props.precioPetro}
                    // localizador={this.props.datosAirBook.BookingReference.Id}
                    bancoDebitoValue={this.state.bancoPFDebito}
                    // correoPrincipal={this.props.datosAirBook.TravelInfos[0].Email}
                    //conexus
                    cedula_conexus={this.setCedulaConexus}
                    banco_conexus={this.setBancoConexus}
                    telefono_conexus={this.setTelefonoConexus}
                    otp_conexus={this.setOtpConexus}
                    //pm
                    banco_pm={this.setBancoPm}
                    telefono_pm={this.setTelefonoPm}
                    coin_metodo_pago={this.props.coin_metodo_pago}
                    precio_pelicula_bs={this.props.precio_pelicula_bs}
                    monto_usd_view={this.props.monto_usd_view}
                    monto_bs_view={this.props.monto_bs_view}
                  />
                </MDBCol>
                <MDBContainer>
                  <MDBRow style={{justifyContent: 'center'}}>
                    <MDBCol style={{padding: 0}}>
                      <MDBBtn
                        className="boton-modal-1-cancelar" 
                        style={{borderRadius:"0"}}
                        block
                        onClick={() => this.cancelModal()}
                        >
                          Cancelar
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol style={{padding: 0}}>
                          {spinnerContinuar}
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBRow>
            </form>
          </MDBCol>
          {/* <MDBCol sm="12" md="12" lg="4">
            <MDBRow>
              <MDBCol className="mt-2">
                <MDBBtn
                block
                color="green"
                className="white-text"
                onClick={this.salir}
                >
                  Salir
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-1" id="primary">
              <strong style={{color: '#fff'}}>Desglose</strong>
            </MDBRow>
            <MDBRow id="accent" className="pt-3">
              <MDBCol className="border-bottom border-dark">
                <strong>Boleto Adultos</strong>
              </MDBCol>
              <MDBCol className="border-bottom border-dark">
                <p>(x{this.props.adultos})</p>
              </MDBCol>
            </MDBRow>
            <MDBRow id="accent" className="colorAccent">
              <MDBCol className="border-bottom border-dark">
                <strong>Boleto Niños</strong>
              </MDBCol>
              <MDBCol className="border-bottom border-dark">
                <p>(x{this.props.niños})</p>
              </MDBCol>
            </MDBRow>
            <MDBRow id="accent" className="colorAccent">
              <MDBCol>
                <strong>Boleto Infantes</strong>
              </MDBCol>
              <MDBCol>
                <p>(x{this.props.infantes})</p>
              </MDBCol>
            </MDBRow>
            <MDBRow id="primary" between>
              <strong className="h5 left-text" style={{color: '#fff'}}>Tasas y Recargos</strong>
              <MDBIcon 
              icon="angle-down"
              size="2x"
              className="rigth-text cursorSenal"
              style={{color: '#fff'}}
              onClick={this.toggleCollapseTasasyRecargos("TasasyRecargos")}
              />
            </MDBRow>
            <MDBCollapse id="TasasyRecargos" isOpen={this.state.collapseTasasyRecargos} className="colorConviansa">
              <MDBRow id="primary">
                <MDBCol className="border-bottom border-light">
                  {this.state.taxes.map((segment, i) =>
                    <div key={i}>
                      <MDBRow className="h5">
                        <MDBCol size="6">
                          <MDBRow style={{color: '#fff'}}>{segment.code}</MDBRow>
                        </MDBCol>
                        <MDBCol size="3" className="text-justify">
                          <MDBRow style={{color: '#fff'}}>{new Intl.NumberFormat("es-ES").format(segment.amount)}</MDBRow>
                        </MDBCol>                        
                      </MDBRow>
                    </div>
                  )} 
                  <MDBRow className="h5">
                    <MDBCol size="9">
                      <MDBRow><span style={{color: '#fff'}}>Gastos Administrativos</span></MDBRow>
                    </MDBCol>
                    <MDBCol size="3" className="text-justify">
                      <MDBRow style={{color: '#fff'}}>{new Intl.NumberFormat("es-ES").format(this.props.gastosAdmin)}</MDBRow>
                    </MDBCol>                              
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow id="primary" className="pd-2"> 
                <MDBCol>
                  <MDBRow end>
                    <p className="h2" style={{color: '#fff'}}>Total Boleto</p>
                  </MDBRow>
                </MDBCol>
                <MDBCol>
                  <MDBRow center>
                    <p className="h2" style={{color: '#fff'}}>{this.props.montoMuestra} {this.currency}</p>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              {/* <MDBRow id="primary" className="pb-2">
                <MDBCol>
                  <MDBRow end>
                    <p className="h6" style={{color: '#fff'}}>Total PTR</p>
                  </MDBRow>
                </MDBCol>
                <MDBCol>
                  <MDBRow center>
                    <p className="h5" style={{color: '#fff'}}>{this.props.precioPetro}</p>
                  </MDBRow>
                </MDBCol>
              </MDBRow> */}
            {/* </MDBCollapse>
          </MDBCol>  */}
        </MDBRow>

        {/* modales activo */}
            <MDBModal className="" isOpen={this.state.modalSegundoPasoActivo}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Introduzca codigo de verificación</MDBModalHeader>
                <MDBModalBody>
                  <MDBInput 
                    icon="lock" 
                    onChange={this.handleChangeCodigoVerificacionActivo}
                    required
                    maxLength="8"
                    type="text"
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="">
                    <MDBBtn onClick={this.toggleModalSegundoPasoActivo} flat className="">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="">
                    <MDBBtn onClick={this.ejecutarSegundoPasoActivo} flat className="">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>           
            <MDBModal className="marginTop2" isOpen={this.state.modalTercerPasoActivo}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Seleccione la cuenta</MDBModalHeader>
                <MDBCol className="yellow">
                  <span> <MDBIcon icon="exclamation" className="mr-2 red-text" /> <strong>Por favor seleccione la cuenta a debitar para continuar.</strong></span>
                </MDBCol>
                <MDBModalBody className="formulario">
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    {this.state.cuentasBancariasActivo.map((e, i) =>
                      <div>
                        <input
                          onChange={this.handleChangeTercerPCuentaActivo} 
                          value={e} 
                          name="cuentaTercerPaso"
                          type="radio"
                          id={i}
                        />
                        <label htmlFor={i}>
                          <MDBRow className="ml-1">
                            Cuenta: {this.state.datosDebitoSegundoPasoActivo.cuentas[i]}
                          </MDBRow>
                        </label>
                      </div> 
                    )}
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="">
                    <MDBBtn onClick={this.toggleModalTercerPasoActivo} flat className="">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="">
                    <MDBBtn onClick={this.ejecutarTercerPasoActivo} flat className="">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            <MDBModal className="marginTop2" isOpen={this.state.modalCuartoPasoActivo}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Confirmar el monto</MDBModalHeader>
                <MDBModalBody>
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    <p className="h3 text-center">Monto a pagar: {this.props.monto} {this.props.empresa === "MOVILNET" || this.props.empresa === "SAPI" ? <div>500.00 Bs.</div> : null}</p>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="colorConviasaG">
                    <MDBBtn onClick={this.toggleModalCuartoPaso} flat className="white-text">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="colorConviasa">
                    <MDBBtn onClick={this.ejecutarCuartoPaso} flat className="white-text">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            {/* modales tesoro */}
            <MDBModal className="marginTop2" isOpen={this.state.modalSegundoPaso}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Introduzca Codigo de verificación</MDBModalHeader>
                <MDBModalBody>
                  <MDBInput 
                    icon="lock" 
                    onChange={this.handleChangeCodigoVerificacion}
                    required
                    maxLength="6"
                    type="text"
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="">
                    <MDBBtn onClick={this.toggleModalSegundoPaso} flat className="">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="">
                    <MDBBtn onClick={this.ejecutarSegundoPaso} flat className="">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>           
            <MDBModal className="marginTop2" isOpen={this.state.modalTercerPaso}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Seleccione la cuenta</MDBModalHeader>
                <MDBCol className="yellow">
                  <span> <MDBIcon icon="exclamation" className="mr-2 red-text" /> <strong>Por favor seleccione la cuenta a debitar para continuar.</strong></span>
                </MDBCol>
                <MDBModalBody className="formulario">
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    {this.state.cuentasBancarias.map((e, i) =>
                      <div>
                        <input
                          onChange={this.handleChangeTercerPCuenta} 
                          value={e} 
                          name="cuentaTercerPaso"
                          type="radio"
                          id={i}
                        />
                        <label htmlFor={i}>
                          <MDBRow className="ml-1">
                            Cuenta: {this.state.datosDebitoSegundoPaso.cuentas[i]}
                          </MDBRow>
                        </label>
                      </div> 
                    )}
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="">
                    <MDBBtn onClick={this.toggleModalTercerPaso} flat className="">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="">
                    <MDBBtn onClick={this.ejecutarTercerPaso} flat className="">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            <MDBModal className="marginTop2" isOpen={this.state.modalCuartoPaso}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Confirmar el monto</MDBModalHeader>
                <MDBModalBody>
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    <p className="h3 text-center">Monto a pagar: {this.props.monto}</p>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="colorConviasaG">
                    <MDBBtn onClick={this.toggleModalCuartoPasoActivo} flat className="white-text">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="colorConviasa">
                    <MDBBtn onClick={this.ejecutarCuartoPasoActivo} flat className="white-text">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            {/* modales bod */}
            <MDBModal className="marginTop2" isOpen={this.state.modalSegundoPasoBod}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Introduzca Codigo de verificación Bod</MDBModalHeader>
                <MDBModalBody>
                  <MDBInput 
                    icon="lock" 
                    onChange={this.handleChangeCodigoVerificacionBod}
                    required
                    maxLength="4"
                    type="text"
                  />
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="colorConviasaG">
                    <MDBBtn onClick={this.toggleModalSegundoPasoBod} flat className="white-text">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="colorConviasa">
                    <MDBBtn onClick={this.ejecutarSegundoPasoBod} flat className="white-text">{spinnerContinuar}</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            <MDBModal className="marginTop2" isOpen={this.state.modalTercerPasoBod}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Seleccione la cuenta</MDBModalHeader>
                <MDBCol className="yellow">
                  <span> <MDBIcon icon="exclamation" className="mr-2 red-text" /> <strong>Por favor seleccione la cuenta a debitar para continuar.</strong></span>
                </MDBCol>
                <MDBModalBody className="formulario">
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    {this.state.cuentasBancariasBod.map((e, i) =>
                      <div>
                        <input
                          onChange={this.handleChangeTercerPCuentaBod} 
                          value={e} 
                          name="cuentaTercerPaso"
                          type="radio"
                          id={i}
                        />
                        <label htmlFor={i}>
                          <MDBRow className="ml-1">
                            Cuenta: {this.state.cuentasMuestraBod[i]}
                          </MDBRow>
                        </label>
                      </div> 
                    )}
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                  <div md="4" className="colorConviasaG">
                    <MDBBtn onClick={this.toggleModalTercerPasoBod} flat className="white-text">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="colorConviasa">
                    <MDBBtn onClick={this.ejecutarTercerPasoBod} flat className="white-text">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            <MDBModal className="marginTop2" isOpen={this.state.modalCuartoPasoBod}>
              <form>
                <MDBModalHeader className="colorConviasa white-text">Confirmar el monto</MDBModalHeader>
                <MDBModalBody>
                  <MDBRow center className="pt-2 colorAccent caja radio radioMetodoPago">
                    <p className="h3 text-center">Monto a pagar: {this.props.monto}</p>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>  
                  <div md="4" className="colorConviasaG">
                    <MDBBtn onClick={this.toggleModalCuartoPasoBod} flat className="white-text">Cancelar</MDBBtn>
                  </div>
                  <div md="4" className="colorConviasa">
                    <MDBBtn onClick={this.ejecutarCuartoPasoBod} flat className="white-text">Aceptar</MDBBtn>
                  </div>
                </MDBModalFooter>
              </form>
            </MDBModal>
            
            {/* modal errores */}
            <MDBContainer >
              <ModalErrores 
                toggleModalError={this.toggleModalError}
                modalErrores={this.state.modalErrores} 
                mensajeError={this.state.mensajeError} 
                codigoError={this.state.codError} 
              />
            </MDBContainer>
      </div>
    )
  }
}

export default index
